import React from 'react';
import { withRouter } from 'react-router-dom';

import './Certificate2.scss';

import img from 'images/stamp@4x copy.png';
import badgeImg from 'images/medal@4x copy.png';
import bgVertical from 'images/bg-vertical.png';

const qs = require('qs');

const Certificate2 = (props) => {

  const query = qs.parse(props.location.search.replace('?', ''));

  return (
    <div
      className="certificate-2-container"
    >
      <div className="row row-eq-height">
        <div className="certificate-left">
          <img className="certificate-badge" src={badgeImg} alt="" />
        </div>
        <div className="certificate-right">
          <div className="certificate-main">
            <div className="certificate-main__content">
              <h1>CERTIFICATE</h1>
              <h3>OF TRAINING AND COURSE COMPLETION</h3>
              <p style={{ fontFamily: 'Amadeus-Light' }}>This certiﬁcate is awarded to</p>
                <h2 className="member-name">{query.name}</h2>
              <p style={{ fontFamily: 'Amadeus-Light', marginBottom: 10 }}>In recognition of the participation and successful completion of the</p>
              <p>“Amadeus Payments Academy Level 1”</p>
            </div>
            <div className="row row-eq-height signature-section">
              <div className="signature-left">
                <div>
                  <p style={{ fontFamily: 'Amadeus-Light' }}>David Doctor</p>
                  <p className="text-small">Executive Vice President, Payments, Amadeus</p>
                </div>
              </div>
              <div className="signature-center">
                <img src={img} alt="" />
              </div>
              <div className="signature-right">
                <div>
                  <p style={{ fontFamily: 'Amadeus-Light' }}>Manuel Bogajo</p>
                  <p className="text-small">Head of Consulting, Travel Payments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Certificate2);
