import React, { useState, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import {
  Home,
  Login,
  Login1,
  LevelOne,
  LevelTwoOverview,
  LevelThreeBespoke,
  // Certificate,
  PrivacyPolicy,
  Logout,
  Certificate2,
  // CertificatePrint,
  NotFound,
} from 'screens';

import Store from 'store';

import './styles/styles.scss';

const Router = () => {

  const [ready, setReady] = useState(false);

  useEffect(() => {
    Store.User.onReady()
    .then(() => {
      setReady(true);
    });
  }, []);

  if (!ready) return null;
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/login1" component={Login1} exact />
        <Route path="/logout" component={Logout} exact />
        <Route path="/level-1-training" component={LevelOne} exact />
        <Route path="/level-2-overview" component={LevelTwoOverview} exact />
        <Route path="/level-3-bespoke" component={LevelThreeBespoke} exact />
        {/* <Route path="/certificate" component={Certificate} exact /> */}
        <Route path="/certificate2" component={Certificate2} exact />
        {/* <Route path="/c/:code" component={CertificatePrint} exact /> */}
        <Route path="/privacy-policy" component={PrivacyPolicy} exact />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
