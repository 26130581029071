import React from 'react';

import './Banner.scss';

import bannerImg from 'images/home-banner.png';
import iconImg from 'images/arrow-down-white.png';

interface Props {
  img: string,
  title: string
}
export default (props) => {
  return (
    <div className="banner" style={{ backgroundImage: `url(${props.img})` }}>
      <div className="container main-banner">
      	<div className="row">
      		<div className="col-12 col-md-8">
      			<h1>{props.title}</h1>
      		</div>
      	</div>
      </div>
    </div>
  );
};
