import React from 'react';
import ResponsiveModal from 'react-responsive-modal';

import './Modal.scss';

interface Props {
  children: any,
  open: boolean,
  onClose(): void,
  styles?: any,
}

export default (props : Props) => {
  const { open, onClose, children, styles } = props;
  return (
    <ResponsiveModal
      open={open}
      onClose={onClose}
      center
      classNames={{
        modal: 'app-modal',
        closeButton: 'app-modal__close-button',
      }}
      styles={styles}
    >
      {children}
    </ResponsiveModal>
  );
}