import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Container } from 'components';
import './Instruction.scss';

import amadeusWhiteImg from 'images/amadeus-icon-white.png';
import professorAvatarImg from 'images/professor-avatar.png';

const Instruction = ({ isCompleted, certificateUrl, onStart }) => {
  return (
    <Container>
      <div className="level1-instructions">
        
        <div className="row">
          {isCompleted && certificateUrl &&
          <div className="col-12 col-md-8 offset-md-2 mb-3">
            <div className="lv1-quiz message-middle">
              <h4 className="lv1-quiz__q-a__quiz-number">You have completed this course.</h4>
              <a className="lv1-quiz__q-a__quiz-number pointer" href={certificateUrl} target="_blank"><u>Download Certificate</u></a>
            </div>
          </div>}

          <div className="col-12 col-md-8 offset-md-2">
            {/* <div className="amadeus-logo">
              <img src={amadeusWhiteImg} alt="" />
            </div> */}

            <h3>Welcome to the Amadeus Payments Academy!</h3>
            {/* <p>
              <span className="lessons">Lessons: </span>9
              <span className="quizzes">Quizzes: </span>9
            </p> */}
            <p className="description">
              It's great to have you onboard. This is Level 1 of the Travel Payments Academy, a three-level course designed for Travel industry professionals. Throughout this series of videos and quizzes we'll introduce you to the world of travel payments, and why it's an important part of any travel business.
            </p>
            <p className="description">
              In this free online course, we'll share the fundamentals of business models, payment concepts and variables that you can take away to develop your payment strategies and execute in your business.
            </p>
            <p className="description">
              To complete the course, you will need to sucessfully pass eight quizzes. To complete each step, watch the video, read the support slides and then click the "Quiz" tab below each video to test your new payment knowledge. Good luck!
            </p>

            {/* <div className="line" /> */}

            <h5>Course professors</h5>
            <div className="row">
              <div className="col-12 col-md-2 mt-3">
                <img className="avatar" src={professorAvatarImg} alt="Manalo Bogajo" />
              </div>
              <div className="col-12 col-md-10 mt-3">
                <h5 className="professor__name" style={{ marginTop: 8 }}>Manolo Bogajo</h5>
                <h6 className="professor__title">Head of Payments Consulting</h6>
              </div>
              <div className="col-12 mt-3">
                <p className="professor__description">Coming from a Pan European Payment Services Provider, Manolo joined Amadeus in 2017 to lead the Payments Consulting practice. He has strong consulting and business management fundamentals fed by more than 10 years of experience in the payments business.</p>
                <button onClick={onStart}>Start the course</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Container>
  );
}

export default Instruction;