import React, { useState, useEffect } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import Store from 'store';
import { BackgroundContainer, Container, Header, Modal } from 'components';

import './Login.scss';
import bgImg from 'images/bg-1.jpg';
import bgImgLv23 from 'images/large-background.png';

const qs = require('qs');

const Login = (props) => {

  const query = qs.parse(props.location.search.replace('?', ''));

  const [showSignIn, setShowSignIn] = useState(false);

  const [state, setState] = useState({
    email: '',
    password: '',
    showRegisterModal: false,
    registerName: '',
    registerEmail: '',
    registerCompany: '',
    registerIndustry: '',
    registerCountry: '',
    registerPassword: '',
    registerPassswordConfirm: '',
    policyCheck: false,
    redirect: (() => {
      if (window.location.href === 'https://986840academy.com/login1?redirect=/level-1-training&utm_campaign=PAY%20Payments%20Academy%20Registration%20EML%20202006&utm_medium=email&utm_source=Eloqua') return '/';
      return query.redirect;
    })(),
    showForgotPasswordModal: false,
    showSuccessModal: false,
    hasResetCode: false,
    forgotEmail: '',
    newPassword: '',
    resetCode: '',
    showSuccessUpdatePasswordText: false,
    isNotLevel1: !query.redirect || !query.redirect.includes("/level-1"),
  });

  const changeState = (label) => (e) => {
    setState({ ...state, [label]: !!e.target ? e.target.value : e })
  }

  if (Store.User.isLoggedIn()) {
    return <Redirect to={state.redirect || "/"} />
  }

  const onLogin = async e => {
    e.preventDefault();
    const { email, password } = state;
    if (!email || !password) return alert('Please check your form inputs');
    const [res, err] = await Store.User.login({ email, password });
    if (err) alert(String(err));
    if (res.error) return alert(res.error);
    console.log(res);
    props.history.push(state.redirect || '/')
  };

  const onRegister = async e => {
    e.preventDefault();
    const { registerPassword, registerPassswordConfirm, registerEmail, registerCompany, registerIndustry, registerCountry, registerName, policyCheck } = state;
    // if (!policyCheck) return alert('Please make sure you have read the policy before continue');
    if (!registerPassword || !registerPassswordConfirm || !registerEmail || !registerName) return alert('Please check your form inputs');
    if (registerPassword !== registerPassswordConfirm) return alert('Passwords dont match');
    const [res, err] = await Store.User.register({
      email: registerEmail,
      password: registerPassword,
      name: registerName,
      company: registerCompany,
      industry: registerIndustry,
      country: registerCountry,
    });
    if (res.error) return alert(res.error);
    if (err) alert(String(err));
    console.log(res);
    props.history.push(state.redirect || '/')
  };

  const onForgotPassword = async e => {
    e.preventDefault();
    const { forgotEmail } = state;
    if (!forgotEmail) return alert('Please check email input.');
    const [res, err] = await Store.User.forgotPassword({ email: forgotEmail });
    if (err) alert(String(err));
    if (res.error) return alert(res.error);
    setState({
      ...state,
      hasResetCode: true,
    });
  };

  const onUpdatePassword = async e => {
    e.preventDefault();
    const { forgotEmail, resetCode, newPassword } = state;
    if (!forgotEmail || !resetCode || !newPassword) return alert('Please check your inputs.');
    const [res, err] = await Store.User.updatePassword({ email: forgotEmail, resetCode, newPassword });
    if (err) alert(String(err));
    if (res.error) return alert(res.error);
    setState({
      ...state,
      showSuccessModal: true,
      showSuccessUpdatePasswordText: true,
      showForgotPasswordModal: false,
    });
  }

  return (
    <BackgroundContainer className="login-page" img={state.isNotLevel1 ? bgImgLv23 : bgImg} withGreyGradientOverlay>
      <Container>
        <Header
          hasIconHome
          rightColors={[state.isNotLevel1 ? "white" : "blue", "white"]}
        />

        <div className="row main-content position-relative" style={{ marginTop: 40 }}>
          <div className="col-12 col-md-4 heading" style={window.innerWidth > 480 ? { position: 'fixed', bottom: 60, left: 40 } : undefined}>
            {state.isNotLevel1 ? (
              <>
                <h1>Welcome to the</h1>
                <h1>Amadeus Payments Academy</h1>
                <h4>Enhance your knowledge and expertise of travel payments</h4>
                <h4>by enrolling in the Amadeus Payments Academy.</h4>
              </>
            ) : (
              <>
                <h1>Travel Payments</h1>
                <h1>Certification Level 1:</h1>
                <p style={{ fontSize: '1.2em' }}>In this free online course, you'll discover:</p>
                <ul>
                  <li>Payments fundamentals and Ecosystem</li>
                  <li>The roles of key players including Issuers, Acquirers, PSPs, Schemes and Regulators</li>
                  <li>Authorization, clearing and settlement, reconciliation and chargeback</li>
                  <li>The world of Alternative Methods of Payments (AMOPS)<br />...and much more!</li>
                </ul>
              </>
            )}
          </div>
          {Boolean(showSignIn) ? (
            <div className="col-12 col-md-4 offset-md-6 login-form">
              <form className="form" onSubmit={onLogin}>
                <span className="close-right pointer" style={{ right: 100 }} onClick={() => setShowSignIn(false)}>X</span>
                <h5 style={{ clear: 'both' }}>Sign in to your learner profile</h5>
                <input placeholder="Email" type="text" value={state.email} onChange={e => setState({ ...state, email: e.target.value })} />
                <input placeholder="Password" type="password" autoComplete="off" value={state.password} onChange={e => setState({ ...state, password: e.target.value })} />
                {/* <p><a href="#" onClick={() => changeState('showForgotPasswordModal')(true)}>Forgotten your password?</a></p> */}
                <input type="submit" value="Log back in" />
                
              </form>
            </div>
          ) : (
            <div className="col-12 col-md-4 offset-md-6 login-form d-flex justify-content-center position-relative">
              <form className="form" onSubmit={onRegister}>
                <span className="close-right pointer" onClick={() => setShowSignIn(true)}>X</span>
                <h5 style={{ clear: 'both' }}>Complete your learner profile</h5>
                <input placeholder="Full Name" type="text" value={state.registerName} onChange={e => setState({ ...state, registerName: e.target.value })} />
                <input placeholder="Email" type="text" name="email" value={state.registerEmail} onChange={e => setState({ ...state, registerEmail: e.target.value })} />
                <input placeholder="Password" type="password" autoComplete="off" value={state.registerPassword} onChange={e => setState({ ...state, registerPassword: e.target.value })} />
                <input placeholder="Re-type Password" type="password" autoComplete="off" value={state.registerPassswordConfirm} onChange={e => setState({ ...state, registerPassswordConfirm: e.target.value })} />
                <p style={{ color: 'black', fontSize: '0.8em' }}>We require your username and password in order for us to track your progress throughout the course. We will record which modules have been viewed and the corresponding quiz results. To read our full privacy statements, please <b><a href="https://amadeus.com/en/policies/amadeus-policies" target="_blank">click here</a></b>.</p>

                <input type="submit" value="Start training" />
                <p style={{ marginBottom: 5, color: 'rgba(0,0,0,0.8)', fontSize: '1em', textAlign: 'center' }}>Already started the course?</p>
                <p onClick={(e) => { e.preventDefault(); setShowSignIn(true); }} style={{ marginBottom: 5, color: 'var(--color-main)', fontSize: '0.9em', textAlign: 'center', textDecoration: 'underline', cursor: 'pointer' }}>Continue here</p>
              </form>
            </div>
          )}
        </div>
        <div className="footer-copyright">
          © Amadeus IT Group SA
          <a href="https://amadeus.com/en/policies/amadeus-policies" target="_blank">Legal Notices & Privacy</a>
        </div>
      </Container>
      <Modal open={state.showRegisterModal} onClose={() => changeState('showRegisterModal')(false)}>
        <form className="form register-form" onSubmit={onRegister}>
          <h4>Register</h4>
          <input placeholder="Full Name" type="text" value={state.registerName} onChange={e => setState({ ...state, registerName: e.target.value })} />
          <input placeholder="Email" type="text" name="email" value={state.registerEmail} onChange={e => setState({ ...state, registerEmail: e.target.value })} />
          {/* <input placeholder="Company" type="text" value={state.registerCompany} onChange={e => setState({ ...state, registerCompany: e.target.value })} />
          <select onChange={e => setState({ ...state, registerIndustry: e.target.value })}>
            <option value="" disabled selected>Select industry</option>
            <option value="Airline">Airline</option>
            <option value="Travel Agent">Travel Agent</option>
            <option value="Hospitality">Hospitality</option>
            <option value="Other">Other</option>
          </select>
          <input placeholder="Country" type="text" value={state.registerCountry} onChange={e => setState({ ...state, registerCountry: e.target.value })} /> */}
          <input placeholder="Password" type="password" autoComplete="off" value={state.registerPassword} onChange={e => setState({ ...state, registerPassword: e.target.value })} />
          <input placeholder="Re-type Password" type="password" autoComplete="off" value={state.registerPassswordConfirm} onChange={e => setState({ ...state, registerPassswordConfirm: e.target.value })} />
          {state.isNotLevel1 ? (
            <>
              <label>
                <input type="checkbox" />
                I give my consent to be contacted by the Amadeus Payments Team.
              </label>
              <label className="mt-0">
                <input type="checkbox" checked={state.policyCheck} onChange={e => changeState('policyCheck')(e.target.checked)} />
                <a target="_blank" href="https://amadeus.com/en/policies/amadeus-policies" rel="noopener noreferrer">I confirm I have read the full privacy policy available <strong style={{ color: 'var(--color-main)' }}>here</strong></a>.
              </label>
            </>
          ) : (
            <p style={{ color: 'black', fontSize: '0.8em' }}>This username and password is required in order for us to track your progress throughout the course. We will record which modules have been viewed and the corresponding quiz results. On successful completion of the course, we will use your email address to send you personalized certificate. To read our full privacy statements, please <b><a href="https://amadeus.com/en/policies/amadeus-policies" target="_blank">click here</a></b>.</p>
          )}
          <input type="submit" value="Register now →" />
          <span className="close pointer" onClick={() => changeState('showRegisterModal')(false)}>X</span>
        </form>
      </Modal>
      <Modal open={state.showForgotPasswordModal} onClose={() => changeState('showForgotPasswordModal')(false)}>
        {state.hasResetCode ? (
          <form className="form forgot-password-form" onSubmit={onUpdatePassword}>
            <h4>Update password</h4>
            <p>Please check your mail box for reset code and use it to set a new password.</p>
            <input placeholder="Email" type="text" value={state.forgotEmail} onChange={e => setState({ ...state, forgotEmail: e.target.value })} />
            <input placeholder="New Password" type="password" autoComplete="off" value={state.newPassword} onChange={e => setState({ ...state, newPassword: e.target.value })} />
            <input placeholder="Reset Code" type="text" value={state.resetCode} onChange={e => setState({ ...state, resetCode: e.target.value })} />
            <p><a href="#" onClick={() => setState({ ...state, hasResetCode: false })}>← Back</a></p>
            <input type="submit" value="Update →" />
            <span className="close pointer" onClick={() => changeState('showForgotPasswordModal')(false)}>X</span>
          </form>
        ) : (
          <form className="form forgot-password-form" onSubmit={onForgotPassword}>
            <h4>Forgot password</h4>
            <input placeholder="Email" type="text" value={state.forgotEmail} onChange={e => setState({ ...state, forgotEmail: e.target.value })} />
            <p><a href="#" onClick={() => setState({ ...state, hasResetCode: true })}>Have a reset code?</a></p>
            <input type="submit" value="Send →" />
            <span className="close pointer" onClick={() => changeState('showForgotPasswordModal')(false)}>X</span>
          </form>
        )}
      </Modal>
      <Modal open={state.showSuccessModal} onClose={() => changeState('showSuccessModal')(false)}>
        <p>{state.showSuccessUpdatePasswordText ? 'Your password has been updated!' : 'We have sent your forgotten password email.'}</p>
      </Modal>
    </BackgroundContainer>
  );
};

export default withRouter(Login);
