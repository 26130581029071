import React, { useState } from 'react';
import Store from 'store';
import './LevelRegister.scss';

interface Props {
  level: number,
  onRegister?(info: any): any,
}

export default (props : Props) => {
  const userData = Store.User.userData || {};
  const [state, setState] = useState({
    fullName: userData.name,
    companyName: userData.company,
    email: userData.email,
    phone: '',
    question: '',
    industry: userData.industry,
    country: userData.country,
    checkbox: false,
  });

  console.log('state', state);

  const genInputProps = (stateLabel, key = 'value') => ({
    value: state[stateLabel],
    onChange: e => setState({ ...state, [stateLabel]: e.target[key] }),
  });

  const register = (e) => {
    !!e && e.preventDefault();
    const { checkbox, fullName, companyName, email, phone, industry, country, question } = state;
    if (!checkbox) return alert('You must read the policy before continue!');
    if (!fullName || !companyName || !email || !industry || !country) {
      return alert('Please check your form inputs!');
    }
    !!props.onRegister && props.onRegister({ fullName, companyName, email, phone, industry, country, question });
  }

  return (
    <div className="register-section">
    	<div className="row">
    		<div className="col-12">
    			<h2 className="heading">How do I get to go?</h2>
    			<h2 className="heading small-space">If you would like to know more about this level of training.</h2>
    			<h2 className="heading small-space">Please email:</h2>
    			<h2 className="heading small-space"><a href="mailto:travel.payments@amadeus.com">travel.payments@amadeus.com</a></h2>
    		</div>
    	</div>
    </div>
  );
};
