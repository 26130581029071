import React, { Fragment } from 'react';
import classnames from 'classnames';

import './LevelOverview.scss';
interface Props {
  data: any
}
export default (props) => {
  return (
    <div className="overview-section">
    	<div className="row">
    		<div className="col-12">
    			<h2 className="heading">Overview</h2>
          <p className="overview">{props.data.introduction}</p>
    		</div>
    	</div>
      <div className="row">
        <div className="col-12">
          <div id="qa-accordion" className="qa-section">
            {props.data.questions.map((item, i) => (
              <div className="qa-item" key={i}>
                <div className="item-header">
                  <button className="item-toggle collapsed" data-toggle="collapse" data-target={`#collapse-${i}`} aria-expanded="true" aria-controls={`collapse-${i}`}>
                    {item.question}
                  </button>
                </div>
                <div id={`collapse-${i}`} className="collapse collapsed">
                  <div className="item-body">
                    {Array.isArray(item.answer)
                    ?
                      <ul>
                        {item.answer.map((a_item, a_i) => {
                          console.log(a_item);
                          if (!a_item.title)
                          return (
                            <li key={a_i}>&#10003; {a_item}</li>
                          );

                          return (
                            <Fragment key={a_i}>
                              {!a_item.subContentTick ? <li>&#10003; {a_item.title}</li> : <li>{a_item.title}</li>}
                              {Array.isArray(a_item.subContent) && a_item.subContent.map((a_sub_item, a_sub_i) => (
                                <li key={a_i + " " + a_sub_i} className={classnames("sub-content", a_item.subContentTick ? "tick" : "")}>{a_sub_item}</li>
                              ))}
                            </Fragment>
                          )
                        })}
                      </ul>
                    :
                      <p>{item.answer}</p>
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
