import React from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Main, HomeBannerSlider, LevelIntroduction, Benefits, HomeSlider, Footer } from 'components';

import './Home.scss';

const Home = () => {
  return (
    <div className="home-page">
      <Main>
        <div className="home-header">
          <div className="container">
            <Header
              // hasLoginButton
              hasIconHome={false}
              rightColors={["white", "", "white"]}
            />
          </div>
        </div>
        <HomeBannerSlider />
        <div className="benefit-section">
          <div className="container">
            <div className="benefit-heading">
              <h3>Elevate your travel payments business strategy to new heights</h3>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2">
                <p className="text-center">Improve your knowledge of travel payments by enrolling in the Amadeus Payments Academy. Throughout this three-tier training program, you’ll join our payments experts as you take a deep dive into the complex world of payments. You will emerge with a detailed understanding of payments terminology, processes and concepts and how they apply to the unique travel ecosystem. Whether you’re new to the world of travel payments or a seasoned veteran, there’s a suitable course for you. And, with on-demand online training and face-to-face seminars available, you can learn in the way that suits you best.
</p>
              </div>
            </div>
          </div>
        </div>
        <Benefits />
        <LevelIntroduction />
        <HomeSlider />
      </Main>
      <Footer isWhiteFooter={true} />
    </div>
  );
};

export default withRouter(Home);
