import React from 'react';
import { withRouter } from 'react-router-dom';
import { Main, Header, Banner, Footer } from 'components';

import './PrivacyPolicy.scss';

import bgImg from 'images/bg-level-2.png';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <Main>
        <div className="banner-header">
          <div className="container">
            <Header
              hasLoginButton
              hasIconHome
              rightColors={["blue", "blue", "blue"]}
            />
          </div>
        </div>
        <Banner
          img={bgImg}
          title="Privacy policy"
        />
        <div className="container">
          <div className="content pt-5">
            <div className="row justify-content-center">
              <div className="col-sm-10 col-md-8 col-lg-6">
                <div className="mb-5">
                  <h3>In this section you will find links to the relevant Amadeus Privacy Notices, Legal Notices and other relevant polices.</h3>
                </div>
                <div className="mb-5">
                  <h3 className="text-primary">Privacy Notices</h3>
                  <div>
                    <p>Amadeus is committed to protecting the privacy of personal data it collects and processes.</p>
                    <p>To understand how Amadeus collect and use personal data and the rights individuals have in relation to personal data processed by Amadeus please refer to the relevant Privacy Notices below:</p>
                  </div>
                  <div className="row">
                    <div className="col-md-6"><a href="#">AMADEUS.COM PRIVACY NOTICE</a></div>
                    <div className="col-md-6"><a href="#">AMADEUS GDS PRIVACY NOTICE</a></div>
                    <div className="col-md-6"><a href="#">BUSINESS PARTNERS PRIVACY NOTICE​</a></div>
                    <div className="col-md-6"><a href="#">AMADEUS.COM COOKIE NOTICE</a></div>
                    <div className="col-md-6"><a href="#">AMADEUS CAREERS SITE PRIVACY NOTICE</a></div>
                  </div>
                </div>
                <div className="mb-5">
                  <h3 className="text-primary">Legal Notices</h3>
                  <div>
                    <p>These Legal Notices set out information about <a href="#">Amadeus.com</a> and the Terms and Conditions on which you may use the site.  By using the site you accept these Legal Notices and agree to be bound by them.</p>
                  </div>
                  <div className="row">
                    <div className="col-md-6"><a href="#">TERMS AND CONDITIONS</a></div>
                  </div>
                </div>
                <div className="mb-5">
                  <h3 className="text-primary">Other Amadeus Notices</h3>
                  <div>
                    <p>Security Policies</p>
                    <p>Amadeus takes security very seriously and works hard to protect customer data. We are constantly striving for improvement and work to protect your data in a safe, secure and high-availability manner consistent with industry standards and best practice.</p>
                  </div>
                  <div className="row">
                    <div className="col-md-6"><a href="#">AMADEUS SECURITY POLICY​ </a></div>
                    <div className="col-md-6"><a href="#">SECURITY ALERTS & TIPS</a></div>
                  </div>
                </div>
                <div className="mb-5">
                  <h3 className="text-primary">Further information</h3>
                  <div>
                    <p>Please find further information about Amadeus and other relevant documentation related to policies and notices.</p>
                  </div>
                  <div className="row">
                    <div className="col-md-6"><a href="#">AMADEUS GDPR INFORMATION</a></div>
                  </div>
                </div>
                <div className="mb-5">
                  <h3 className="text-primary">Travel Advertising</h3>
                  <div>
                    <p>Please find further information about Travel Advertising terms and conditions.</p>
                  </div>
                  <div className="row">
                    <div className="col-md-6"><a href="#">TERMS AND CONDITIONS</a></div>
                  </div>
                </div>
                <div className="mb-5">
                  <h3 className="text-primary mb-4">UK Policies</h3>
                  <div id="accordion-1">
                    <div className="collapse-box border-top">
                      <h3 className="px-4 py-3 mb-0">
                        <a href="#collapse-1" className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse-1">
                          UK Terms of Use
                        </a>
                      </h3>
                      <div id="collapse-1" className="p-2 collapse" data-parent="#accordion-1">
                        <p><b>Amadeus IT Services UK Ltd</b></p>
                        <p>First Point<br />
                          Buckingham Gate<br />
                          Gatwick Airport<br />
                          West Sussex<br />
                          RH6 0NT<br />
                          United Kingdom</p>
                        <p>Tel. +44 (0)843 509 0000</p>
                        <p>info@uk.amadeus.com</p>
                        <div className="mb-5">
                          <h3 className="text-primary">Amadeus Data Privacy Statement</h3>
                          <div>
                            <p>At uk.amadeus.com a site operated by Amadeus IT Services UK Ltd we respect your privacy.</p>
                            <p>This statement outlines Amadeus' policy on the privacy of individuals whose data are processed and/or stored by Amadeus as a result of our provision of services to the travel and tourism industry. This statement also applies for data collected via this website. Amadeus may include links on this website which lead to other parties' websites which are not covered by this privacy statement.</p>
                            <p>This privacy statement explains how we collect data about customers of the travel and tourism industry and users of this website, how we use that data, and to whom that data might be sent.</p>
                            <p>What kind of data does Amadeus collect and how do we collect data about you and your use of the website?</p>
                            <p>Amadeus obtains consumer information from travel agencies, airlines, travel suppliers, corporate travel departments, and other travel related companies with which Amadeus has a legal or business relationship.</p>
                            <p>Information collected includes names, postal addresses, e-mail addresses, telephone numbers, credit card information, and certain preferences you have depending on the services or information you request.</p>
                            <p>Information may be also collected when you visit the Amadeus.com website. In this regard, we also ask you for personal information in a variety of circumstances -<br />
                            - when you register with us, subscribe to our newsletter, enter competitions or register for promotions; or<br />
                            - when you take part in surveys or provide us with feedback.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">How do we use/process your data?</h3>
                          <div>
                            <p>Personal data will be processed in accordance with applicable local law and regulations regarding data privacy. Personal data will be processed, stored and disclosed only for business purposes as described below. We may use your data for the following purposes:</p>
                            <ul>
                              <li>to provide you with the highest possible level of service and to help you to obtain the best service from our website;</li>
                              <li>to notify you about our products and services, special offers and promotions and any other marketing materials which we think may interest you</li>
                              <li>for other administrative purposes and for internal analysis; and</li>
                              <li>to participate as part of a survey or to get feedback.</li>
                            </ul>
                            <p>Non-personal data may be used to compile and analyze travel trends and/or other demographic information.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">To whom may your data be disclosed?</h3>
                          <div>
                            <p>If you are a travel and tourism customer, we will disclose your data to our partners for fulfillment of your booking requests.</p>
                            <p>We will not disclose your data to any third parties except where necessary for the purposes of fulfilling any bookings, purchases or requests that you make on the website, through our customer service team, for credit checks or fraud prevention, or as otherwise described in this statement. We may disclose your information if required by any applicable law, subpoena, or regulation. We may also disclose your data to third parties and professional advisors acting on our behalf who are obliged to keep that data confidential.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">How do we secure your data?</h3>
                          <div>
                            <p>Personal data will be safeguarded from unauthorized use, disclosure, destruction and alteration by physical, technical and organizational security measures that are appropriate to the risks, such as accidental loss or damage or unauthorized access, presented by the processing.<br />Security offered on this website is based on a technology called Secure Socket Layer ("SSL") which lets your browser automatically encrypt or scramble your data before you send it to us.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Transfer of information and Third Party Processing</h3>
                          <div>
                            <p>Where a third party is used to process personal data on behalf of a member of the Amadeus Group, the third party will be obliged to take measures to protect that personal data.</p>
                            <p>If it is necessary to transfer your data to another company (e.g. for the purpose of fulfilling your booking) then we will instruct such companies to process your personal data in accordance with all applicable rules and regulations. We will also take any other required measures to ensure that such transfer is lawful.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Implementation of this Statement</h3>
                          <div>
                            <p>Amadeus staff is aware of and have been instructed to comply with this statement and with applicable data privacy regulations.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Cookies</h3>
                          <div>
                            <p>To make Amadeus work even better for you, we also use 'cookies' to collect information about your use of the website. Cookies are a feature of web browser software that allows web servers to temporarily store information within your browser, which in turn allows us to recognize the computer used to access Amadeus. Most browsers automatically accept cookies, but you can delete existing cookies from your browser. You can also edit your browser options to choose not to receive cookies in the future.</p>
                            <p>To establish how customers reach our site, we collect information through cookies. No personal information (i.e. that can identify you) is collected during this process.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Statement regarding information from minors</h3>
                          <div>
                            <p>Our services are not intended for use by minors. We do not knowingly collect personal information from any minor.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Access to data/Opt out</h3>
                          <div>
                            <p>Upon written request and to the extent required under applicable law, Amadeus or our partners, as appropriate, will inform you about which of your personal data is processed by Amadeus. If any such data should be found to be incorrect, we will correct that information at your request.</p>
                            <p>If you wish us to stop sending you newsletters or details of other offers and promotions you may automatically unsubscribe through the unsubscribe link in our emails or email us at <a href="mailto:info@amadeus.com">info@amadeus.com</a>.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Concerns or Questions regarding your privacy</h3>
                          <div>
                            <p>Any questions or concerns regarding the Amadeus Data Privacy Statement should be directed to <a href="mailto:dataprotection@amadeus.com">dataprotection@amadeus.com</a></p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Design</h3>
                          <div>
                            <p>This website is provided to you by Amadeus IT Group SA. The website design and look and feel is owned by Amadeus. All intellectual property rights related to it belong to Amadeus IT Group SA and/or the affiliates belonging to the Amadeus Group.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Disclaimer</h3>
                          <div>
                            <p>Amadeus does not guarantee availability, access, accuracy, timeliness or any other aspects of the information contained in this website.</p>
                            <p>Amadeus does not warrant the completeness, merchantability or fitness for a particular purpose of any of the data disclosed in the Amadeus website.</p>
                            <p>Amadeus shall not be liable for any injury, loss, claim, damage, or any special, incidental, indirect or consequential damages of any kind which arise out or are in any way connected with any data within the Amadeus website, or resulting from any use or inability to use the Amadeus website.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Links and Public Internet</h3>
                          <div>
                            <p>The Amadeus website may give you access, through links, to third party websites that are not controlled by Amadeus.</p>
                            <p>The public Internet, including the World Wide Web includes a very large number of rapidly changing sites with varied content, operated by parties over whom, and through data communications equipment over which Amadeus has no control.</p>
                            <p>Amadeus IT Group SA is not responsible for the availability, access accuracy, timeliness or any other aspects of the information contained in any third party website which may be accessed through the Amadeus website and its related pages, or which may be connected or linked to it in any other way.</p>
                            <p>The inclusion of contents related to websites operated by third parties does not constitute an endorsement by Amadeus of the contents of such websites or any association with their operators.</p>
                            <p>Amadeus disclaims any liability arising out of any third party's fraudulent, unlawful or otherwise improper use of such third party's website and any third party's fraudulent, unlawful or otherwise improper use of the Amadeus website.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="collapse-box border-top">
                      <h3 className="px-4 py-3 mb-0">
                        <a href="#collapse-2" className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse-2">
                          Gender Gap
                        </a>
                      </h3>
                      <div id="collapse-2" className="p-2 collapse" data-parent="#accordion-1">
                        <div className="mb-5">
                          <h3 className="text-primary">Amadeus London Heathrow Gender Pay Gap Report 2017</h3>
                          <div>
                            <p>Download Amadeus IT Services UK Limited – London Heathrow’s 2017 Gender Pay Gap Report published under The Equality Act 2010 (Gender Pay Gap Information) Regulations 2017.</p>
                            <p><a href="#">Click here to see the Amadeus London Heathrow Gender Pay Gap Report 2017</a></p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Amadeus IT Services UK Limited Gender Pay Gap Report 2018</h3>
                          <div>
                            <p>Download Amadeus IT Services UK Limited Gender Pay Gap Report 2018 here.</p>
                            <p><a href="#">Click here to see the Amadeus Gender Pay Gap Report 2018</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="collapse-box border-top">
                      <h3 className="px-4 py-3 mb-0">
                        <a href="#collapse-3" className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse-3">
                          Amadeus Group Website Policy
                        </a>
                      </h3>
                      <div id="collapse-3" className="p-2 collapse" data-parent="#accordion-1">
                        <h3>AMADEUS GROUP WEBSITE PRIVACY POLICY</h3>
                        <div className="mb-5">
                          <div>
                            <p><i>Last Updated – 04 May 2018</i></p>
                            <p>Amadeus is committed to protecting the privacy of Personal Information it collects and processes. Amadeus will act in compliance with applicable data protection legislation.</p>
                            <p>We, Amadeus Group, want you to be familiar with how we collect, use and disclose Personal Information (information that identifies you as an individual).</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Who is collecting and processing the Personal Information</h3>
                          <div>
                            <p>This Privacy Policy (the “Privacy Policy”) applies to Personal Information that Amadeus IT Group SA may collect through this website (the "Site"). This Privacy Policy does not address the collection, use or disclosure of information through any other means other than the Site. This Privacy Policy does not apply to the Personal Information that Amadeus may process about you as a traveler. If you are interested on understanding these other processes please follow this <a href="#">link</a>.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">What is the legal basis for the processing of the Personal Information</h3>
                          <div>
                            <p>The legal basis for processing your personal data is that the processing is necessary for the performance of the relationship with you, which is regulated by the Terms and Conditions and by the specific forms collecting your data.</p>
                            <p>The legal bases for processing the personal data for research, analytical and statistical purposes and to identify products and services that may be of interest to individuals is on the bases of the legitimate interest of Amadeus. Where personal data is processed for these purposes the privacy impact on the individuals whose data is being processed will be considered.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">What Types of Information Do We Collect?</h3>
                          <div>
                            <p>Amadeus collects and processes information from you at various stages during your time using the Site.</p>
                            <p>The types of Personal Information that we collect and process includes:</p>
                            <li>
                              <p>a. Personal Information:</p>
                              <ul>
                                <li><p>a. Salutation, Name, last name, address, company name, job title, country, indus-try, mobile phone and email address.</p></li>
                                <li><p>b. Any other info you provide to us in contact forms (you will see exactly what in-formation we collect form you by filling the fields in the form);</p></li>
                              </ul>
                            </li>
                            <li><p>b. IP details and location data; and</p></li>
                            <li><p>c. Cookies in accordance with this policy and the <a href="#">Cookie Policy</a></p></li>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">For what purpose is your Personal Information used?</h3>
                          <div>
                            <p>We use the Personal Information collected from you:</p>
                            <ul>
                              <li>To fulfill your requests (e.g. to send you news and updates and answers to your ques-tions), according to the forms you may fill.</li>
                              <li>To obtain your feedback (e.g. through a survey), in case you want to provide us with it.</li>
                              <li>To inform you by e-mail of our products and services, special offers and promotions, and any other marketing materials which we think may interest you.</li>
                              <li>To send you information regarding the Site such as changes to our terms, conditions, and policies and/or other administrative information.</li>
                              <li>For our internal business purposes, such as data analysis, audits, developing new prod-ucts, enhancing our website, improving our services and identifying usage trends. This information may be used as personal information and non-personal information.</li>
                              <li>To detect, prevent or otherwise address security or technical issues in connection with services provided through this Site.</li>
                            </ul>
                            <p>We may combine personal information collected from your navigation with the information provided by you through different channels (newsletters, specific campaigns, contests, etc.) obtaining a set of all your data provided to Amadeus. We will use this combined information to understand which are your preferences regarding the Amadeus services and products and to be able to provide you a targeted experience. We need to understand who are you and what your needs are. Having that knowledge we can offer you what you expect from us.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Unsubscribe</h3>
                          <div>
                            <p>If you change your mind, and no longer wish to receive marketing-related e-mails from us on a going-forward basis, you may unsubscribe from receiving such marketing-related e-mails or from such sharing at any time by using the tool provided to you.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Who is the Personal Information shared with?</h3>
                          <div>
                            <p>We may share Personal Information with Amadeus Affiliates, who, due to the nature of the services Amadeus provides as a global travel technology supplier, may be located in any location around the world. These Amadeus Affiliates may process Personal Information for the purposes described in this Privacy Policy. A link to the complete list of Amadeus Affiliates and their locations can be found <a href="#">here</a>.</p>
                            <p>In addition, we may also disclose your information to:</p>
                            <ul>
                              <li>Third parties, business partners, suppliers, subcontractors and service providers for the performance of any contract we enter into with them;</li>
                              <li>analytics and search engine providers that assist us in the improvement and optimiza-tion of our Site;</li>
                              <li>an affiliate or other third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including without limitation in connection with any bankruptcy or simi-lar proceedings); and</li>
                              <li>if necessary: (a) under applicable law including laws outside your country of residence; (b) to comply with legal processes; (c) to respond to requests from public and gov-ernment authorities including public and government authorities outside your country of residence;(d) to enforce our terms and conditions; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety or property, and/or that of our affiliates, you or others; and (g) to permit us to pursue available remedies or limit the damages that we may sustain.</li>
                            </ul>
                            <p>Where such disclosures take place Amadeus requires the appropriate technical and organizational security measures to be in place to protect personal data and for personal data to be processed lawfully.</p>
                            <p>Amadeus only allow affiliates and third party service providers to use personal data for specified purposes and in accordance with Amadeus instructions.</p>
                            <p>Any sharing of Personal Information using third party platforms would be governed by the terms of the third party platform used.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">International Transfer of Traveler Personal Data</h3>
                          <div>
                            <p></p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary"></h3>
                          <div>
                            <p>When Amadeus share your personal data with its affiliates and third party service providers who process personal data on behalf of Amadeus this will involve transferring personal data outside the EEA. When personal data is transferred to another country it will continue to receive adequate protection through contractual or other arrangements put in place with Affiliates and third party service providers. For these transfers at least one of the following appropriate safeguards will be implemented;</p>
                            <ul>
                              <li>Personal data will be transferred to countries that have been deemed to provide an ad-equate level of protection for personal data by the European Commission;</li>
                              <li>Standard data protection clauses approved by the European Commission which give personal data transferred the same protection it has in EEA;</li>
                              <li>With affiliates and third party service providers based in the US, Privacy Shield which gives personal data similar protection it has in EEA;</li>
                            </ul>
                            <p>Further information on the appropriate safeguards used when transferring personal data outside the EEA can be requested through the contact details set out below in the section legal rights. When requesting this information please make a reference to the transfer of personal data outside the EEA.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Third Party Sites and sending messages through the site</h3>
                          <div>
                            <p>This Privacy Policy does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including without limitation any third party operating any site to which this Site contains a link; or any third party platform such as a social media platforms where Amadeus to share Personal Information at your request. Where Personal Information is shared in this way the use of the Personal Information will be governed by the terms of the third party platform you have requested Personal Information is shared with. The inclusion of a link on the Site does not imply endorsement of the linked site by us.</p>
                            <p>This Site may permit you to send messages regarding Site-related content to another individual though the site. If you wish to use this feature, you may be required to provide us with the individuals email address. By using this functionality, you affirm that you are entitled to use and provide us with the recipient's name and email address for this purpose. Amadeus shall not assume any liability that may arise from sending this kind of messages to the recipients as we are doing this at your request.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Information that you share</h3>
                          <div>
                            <p>Some services may let you share information with others. As a recommendation we urge you to exercise discretion and caution when deciding to disclose your Personal Information, or any other information, on the Site.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Security and Integrity of Personal Information</h3>
                          <div>
                            <p>We use reasonable organizational, technical and administrative measures to protect Personal Information under our control. Unfortunately, no data transmission over the Internet or data storage system can be guaranteed to be 100% secure. Please do not send us sensitive information through e-mail. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), you must immediately notify us of the problem by contacting us in accordance with the contacting section of this Site. Please note that e-mail communications will not necessarily be secure; accordingly you should not include credit card information in your e-mail correspondence with us.</p>
                            <p>When Personal Data is processed on behalf of Amadeus access is limited to those who have a business need to know, Personal Data will be processed in accordance with the instructions of Amadeus and those who have access are subject to a duty of confidentiality.</p>
                            <p>Amadeus have in place procedures to deal with any suspected personal data breach and will notify individuals and any applicable regulator of a breach where they are legally required to do so.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Retention of Personal Information</h3>
                          <div>
                            <p>Amadeus retains personal data for as long as necessary to fulfil the purposes it was collected for including for the purposes of satisfying any legal, accounting or reporting requirements.</p>
                            <p>Please note that on any forms where you provide us with your details, we may specify the period of time that we intend keep the data according to the specific proposes defined in the form.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Use of the Site by Children</h3>
                          <div>
                            <p>The Site is not directed to children, and we request such individuals to not provide Personal Information through the Site.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Legal rights</h3>
                          <div>
                            <p>Under certain circumstances individuals can exercise rights under data protection laws. You can exercise these rights relating to your personal data, or contact Amadeus for data protection related questions, by email to <a href="mailto:dataprotection@amadeus.com">dataprotection@amadeus.com</a> or write to Chief Privacy Officer, Amadeus IT Group, S.A. C/Salvador de Madariaga 1, 28027 Madrid, Spain.</p>
                            <p>For the following rights please make a reference to the following in the request:</p>
                            <ul>
                              <li>Right to access – request for access to personal data</li>
                              <li>Right to object – object to processing of personal data for the purpose of analytics</li>
                              <li>Right to information about
                                <ul>
                                  <li>Amadeus affiliates and third party service providers who process personal data on behalf of Amadeus;</li>
                                  <li>transfers to third countries – information about data transfers outside EEA;</li>
                                </ul>
                              </li>
                            </ul>
                            <p>Amadeus will require authentication of your identity and may require additional information to confirm that the rights that you may have under data protection laws are being exercised correctly.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Your rights</h3>
                          <div>
                            <p>Although Amadeus intends to carefully address any request and/or claim from you, as well as carefully process your personal information, you are entitled to file any claim or complaint before the relevant data protection authorities, if the answer provided by Amadeus does not meet your expectations.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Updates</h3>
                          <div>
                            <p>This Privacy Notice is published by Amadeus IT Group S.A and may be changed at any time. The date it was updated is shown here, May 04, 2018.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="collapse-box border-top">
                      <h3 className="px-4 py-3 mb-0">
                        <a href="#collapse-4" className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse-4">
                          UK Tax Strategy 2018
                        </a>
                      </h3>
                      <div id="collapse-4" className="p-2 collapse" data-parent="#accordion-1">
                        <h3 className="text-primary mb-3">Amadeus IT Services UK Limited UK tax strategy</h3>
                        <h3 className="text-primary mb-3">Year ending 31 December 2018</h3>
                        <div className="mb-5">
                          <h3 className="text-primary">Introduction</h3>
                          <div>
                            <p>The mission of the Amadeus Group (the “Group”) is to be a leading provider of IT solutions that enables success in the travel and tourism industry.</p>
                            <p>Amadeus IT Services UK Limited and Amadeus Hospitality UK Limited (“Amadeus UK”) are the two UK companies of the Group.</p>
                            <p>As a UK business we pay corporation, employment and other taxes. In addition we collect and pay employee taxes and VAT.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Our attitude to tax planning</h3>
                          <div>
                            <p>The Group’s tax strategy sets out a number of principles by which Amadeus’s conduct in respect of tax matters should be governed and can be found at the following address:</p>
                            <p><a href="https://corporate.amadeus.com/documents/en/investors/all-years/corporate-governance/tax-strategy-amadeus-final-.pdf​">https://corporate.amadeus.com/documents/en/investors/all-years/corporate-governance/tax-strategy-amadeus-final-.pdf​</a></p>
                            <p>In alignment with the Group, the tax strategy of Amadeus UK is aimed at the full and strict compliance with all appropriate tax laws and regulations. We do not engage in artificial tax arrangements and seek to minimise the risk of uncertainty or disputes. </p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">The level of risk our business is prepared to accept for UK taxation</h3>
                          <div>
                            <p>Amadeus’s risk profile is moderate and conservative, based on the implementation of a prudent risk management policy, notwithstanding its commitment and obligation to protect the interests of the Group and its shareholders within a framework of support for the business strategy in the long term, avoiding tax risks and inefficiencies in the implementation of business decisions.</p>
                            <p>Amadeus UK’s tax strategy is implemented throughout the company by the tax responsible individuals within the local finance team with support from the Group tax department based in Madrid.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">How we manage UK tax risks</h3>
                          <div>
                            <p>Amadeus UK has a finance team staffed by experienced professionals, including those responsible for UK taxes. External tax advice is sought when the internal tax responsible individuals may not have the necessary expertise.</p>
                            <p>We maintain appropriate accounting systems and controls to support tax compliance obligations.</p>
                            <p>The Group has a dedicated transfer pricing team ensuring all transactions between group companies are conducted on an arm’s-length basis and in accordance with current OECD principles.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">How we work with HMRC</h3>
                          <div>
                            <p>Amadeus’s Group policy sets out our principle of cooperation and collaboration with the tax Authorities in their audit, verification and control activities in respect of Group companies, notwithstanding the legitimate right of the company to disagree with the Tax Authorities’ criteria and to uphold its own position before the courts as long as this is based on a reasonable interpretation of the law.</p>
                            <p>We aim to work with HMRC in an open and transparent manner, respond to information requests in a timely manner and take a cooperative approach to resolve queries.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Any other relevant information related to taxation</h3>
                          <div>
                            <p>This tax strategy has been published in accordance with Schedule 19 of the Finance Act 2016.</p>
                            <p>It was last reviewed and approved on 19th December 2018.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="collapse-box border-top">
                      <h3 className="px-4 py-3 mb-0">
                        <a href="#collapse-5" className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse-5">
                          Modern Slavery Statement
                        </a>
                      </h3>
                      <div id="collapse-5" className="p-2 collapse" data-parent="#accordion-1">
                        <div className="mb-5">
                          <h3 className="text-primary">Modern Slavery Statement</h3>
                          <div>
                            <p>The commitment of Amadeus to ethical business behaviour is a key element of our corporate governance. We all have a responsibility to understand the professional conduct Amadeus expects.</p>
                            <p>Section 54 of the UK Modern Slavery Act requires our Company to publish a modern slavery statement declaring our initiatives to avoid and eliminate modern slavery, both directly and among suppliers.</p>
                            <p><a href="#">Click here to see the Amadeus UK Modern Slavery Statement</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <h3 className="text-primary mb-4">Ireland Policies</h3>
                  <div id="accordion-2">
                    <div className="collapse-box border-top">
                      <h3 className="px-4 py-3 mb-0">
                        <a href="#collapse-6" className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse-6">
                          Ireland Terms of Use
                        </a>
                      </h3>
                      <div id="collapse-6" className="p-2 collapse" data-parent="#accordion-2">
                        <div className="mb-5">
                          <p><b>Amadeus IT Services UK Ltd</b></p>
                          <p>First Point<br />
                            Buckingham Gate<br />
                            Gatwick Airport<br />
                            West Sussex<br />
                            RH6 0NT<br />
                            United Kingdom</p>
                          <p>Tel. +44 (0)843 509 0000</p>
                          <p>info@uk.amadeus.com</p>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Amadeus Data Privacy Statement</h3>
                          <div>
                            <p>At uk.amadeus.com a site operated by Amadeus IT Services UK Ltd we respect your privacy.</p>
                            <p>This statement outlines Amadeus' policy on the privacy of individuals whose data are processed and/or stored by Amadeus as a result of our provision of services to the travel and tourism industry. This statement also applies for data collected via this website. Amadeus may include links on this website which lead to other parties' websites which are not covered by this privacy statement.</p>
                            <p>This privacy statement explains how we collect data about customers of the travel and tourism industry and users of this website, how we use that data, and to whom that data might be sent.</p>
                            <p>What kind of data does Amadeus collect and how do we collect data about you and your use of the website?</p>
                            <p>Amadeus obtains consumer information from travel agencies, airlines, travel suppliers, corporate travel departments, and other travel related companies with which Amadeus has a legal or business relationship.</p>
                            <p>Information collected includes names, postal addresses, e-mail addresses, telephone numbers, credit card information, and certain preferences you have depending on the services or information you request.</p>
                            <p>Information may be also collected when you visit the Amadeus.com website. In this regard, we also ask you for personal information in a variety of circumstances -</p>
                            <p>- when you register with us, subscribe to our newsletter, enter competitions or register for promotions; or</p>
                            <p>- when you take part in surveys or provide us with feedback.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">How do we use/process your data?</h3>
                          <div>
                            <p>Personal data will be processed in accordance with applicable local law and regulations regarding data privacy. Personal data will be processed, stored and disclosed only for business purposes as described below. We may use your data for the following purposes:</p>
                            <ul>
                              <li>to provide you with the highest possible level of service and to help you to obtain the best service from our website;</li>
                              <li>to notify you about our products and services, special offers and promotions and any other marketing materials which we think may interest you</li>
                              <li>for other administrative purposes and for internal analysis; and</li>
                              <li>to participate as part of a survey or to get feedback.</li>
                              <p>Non-personal data may be used to compile and analyze travel trends and/or other demographic information.</p>
                            </ul>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">To whom may your data be disclosed?</h3>
                          <div>
                            <p>If you are a travel and tourism customer, we will disclose your data to our partners for fulfillment of your booking requests.</p>
                            <p>We will not disclose your data to any third parties except where necessary for the purposes of fulfilling any bookings, purchases or requests that you make on the website, through our customer service team, for credit checks or fraud prevention, or as otherwise described in this statement. We may disclose your information if required by any applicable law, subpoena, or regulation. We may also disclose your data to third parties and professional advisors acting on our behalf who are obliged to keep that data confidential.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">How do we secure your data?</h3>
                          <div>
                            <p>Personal data will be safeguarded from unauthorized use, disclosure, destruction and alteration by physical, technical and organizational security measures that are appropriate to the risks, such as accidental loss or damage or unauthorized access, presented by the processing.</p>
                            <p>Security offered on this website is based on a technology called Secure Socket Layer ("SSL") which lets your browser automatically encrypt or scramble your data before you send it to us.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Transfer of information and Third Party Processing</h3>
                          <div>
                            <p>Where a third party is used to process personal data on behalf of a member of the Amadeus Group, the third party will be obliged to take measures to protect that personal data.</p>
                            <p>If it is necessary to transfer your data to another company (e.g. for the purpose of fulfilling your booking) then we will instruct such companies to process your personal data in accordance with all applicable rules and regulations. We will also take any other required measures to ensure that such transfer is lawful.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Implementation of this Statement</h3>
                          <div>
                            <p>Amadeus staff is aware of and have been instructed to comply with this statement and with applicable data privacy regulations.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Cookies</h3>
                          <div>
                            <p>To make Amadeus work even better for you, we also use 'cookies' to collect information about your use of the website. Cookies are a feature of web browser software that allows web servers to temporarily store information within your browser, which in turn allows us to recognize the computer used to access Amadeus. Most browsers automatically accept cookies, but you can delete existing cookies from your browser. You can also edit your browser options to choose not to receive cookies in the future.</p>
                            <p>To establish how customers reach our site, we collect information through cookies. No personal information (i.e. that can identify you) is collected during this process.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Statement regarding information from minors</h3>
                          <div>
                            <p>Our services are not intended for use by minors. We do not knowingly collect personal information from any minor.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Access to data/Opt out</h3>
                          <div>
                            <p>Upon written request and to the extent required under applicable law, Amadeus or our partners, as appropriate, will inform you about which of your personal data is processed by Amadeus. If any such data should be found to be incorrect, we will correct that information at your request.</p>
                            <p>If you wish us to stop sending you newsletters or details of other offers and promotions you may automatically unsubscribe through the unsubscribe link in our emails or email us at <a href="mailto:info@amadeus.com">info@amadeus.com</a>.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Concerns or Questions regarding your privacy</h3>
                          <div>
                            <p>Any questions or concerns regarding the Amadeus Data Privacy Statement should be directed to <a href="mailto:dataprotection@amadeus.com">dataprotection@amadeus.com</a></p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Design</h3>
                          <div>
                            <p>This website is provided to you by Amadeus IT Group SA. The website design and look and feel is owned by Amadeus. All intellectual property rights related to it belong to Amadeus IT Group SA and/or the affiliates belonging to the Amadeus Group.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Disclaimer</h3>
                          <div>
                            <p>Amadeus does not guarantee availability, access, accuracy, timeliness or any other aspects of the information contained in this website.</p>
                            <p>Amadeus does not warrant the completeness, merchantability or fitness for a particular purpose of any of the data disclosed in the Amadeus website.</p>
                            <p>Amadeus shall not be liable for any injury, loss, claim, damage, or any special, incidental, indirect or consequential damages of any kind which arise out or are in any way connected with any data within the Amadeus website, or resulting from any use or inability to use the Amadeus website.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Links and Public Internet</h3>
                          <div>
                            <p>The Amadeus website may give you access, through links, to third party websites that are not controlled by Amadeus.</p>
                            <p>The public Internet, including the World Wide Web includes a very large number of rapidly changing sites with varied content, operated by parties over whom, and through data communications equipment over which Amadeus has no control.</p>
                            <p>Amadeus IT Group SA is not responsible for the availability, access accuracy, timeliness or any other aspects of the information contained in any third party website which may be accessed through the Amadeus website and its related pages, or which may be connected or linked to it in any other way.</p>
                            <p>The inclusion of contents related to websites operated by third parties does not constitute an endorsement by Amadeus of the contents of such websites or any association with their operators.</p>
                            <p>Amadeus disclaims any liability arising out of any third party's fraudulent, unlawful or otherwise improper use of such third party's website and any third party's fraudulent, unlawful or otherwise improper use of the Amadeus website.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <h3 className="text-primary mb-4">France</h3>
                  <div id="accordion-3">
                    <div className="collapse-box border-top">
                      <h3 className="px-4 py-3 mb-0">
                        <a href="#collapse-7" className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse-7">
                          France Gender Equality Index
                        </a>
                      </h3>
                      <div id="collapse-7" className="p-2 collapse" data-parent="#accordion-3">
                        <div className="mb-5">
                          <div>
                            <p><i>Last Updated – 25 Feb 2020</i></p>
                            <p>The Amadeus offices located in France, are exceptionally multicul-tural and diverse (age, profiles and gender mix).</p>
                            <p>Amadeus Nice, France index for 2020 is equal to 86.</p>
                            <p>Amadeus Paris- Strasbourg, France index for 2020 is equal to 92.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <h3 className="text-primary mb-4">United States</h3>
                  <div id="accordion-4">
                    <div className="collapse-box border-top">
                      <h3 className="px-4 py-3 mb-0">
                        <a href="#collapse-8" className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse-1">
                          US Privacy Policy
                        </a>
                      </h3>
                      <div id="collapse-8" className="p-2 collapse" data-parent="#accordion-4">
                        <div className="mb-5">
                          <div>
                            <p><i>Last Updated – 04 May 2018</i></p>
                            <p>Amadeus is committed to protecting the privacy of Personal Information it collects and processes. Amadeus will act in compliance with applicable data protection legislation.</p>
                            <p>We, Amadeus Group, want you to be familiar with how we collect, use and disclose Personal Information (information that identifies you as an individual).</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Who is collecting and processing the Personal Information</h3>
                          <div>
                            <p>This Privacy Policy (the “Privacy Policy”) applies to Personal Information that Amadeus IT Group SA may collect through this website (the "Site"). This Privacy Policy does not address the collection, use or disclosure of information through any other means other than the Site. This Privacy Policy does not apply to the Personal Information that Amadeus may process about you as a traveler. If you are interested on understanding these other processes please follow this <a href="#">link</a>.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">What is the legal basis for the processing of the Personal Information</h3>
                          <div>
                            <p>The legal basis for processing your personal data is that the processing is necessary for the performance of the relationship with you, which is regulated by the Terms and Conditions and by the specific forms collecting your data.</p>
                            <p>The legal bases for processing the personal data for research, analytical and statistical purposes and to identify products and services that may be of interest to individuals is on the bases of the legitimate interest of Amadeus. Where personal data is processed for these purposes the privacy impact on the individuals whose data is being processed will be considered.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">What Types of Information Do We Collect?</h3>
                          <div>
                            <p>Amadeus collects and processes information from you at various stages during your time using the Site.</p>
                            <p>The types of Personal Information that we collect and process includes:</p>
                            <ul>
                              <li>
                                <p>a. Personal Information:</p>
                                <ul>
                                  <li>
                                    <p>a. Salutation, Name, last name, address, company name, job title, country, indus-try, mobile phone and email address.</p>
                                  </li>
                                  <li>
                                    <p>b. Any other info you provide to us in contact forms (you will see exactly what in-formation we collect form you by filling the fields in the form);</p>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <p>b. IP details and location data; and</p>
                              </li>
                              <li>
                                <p>c. Cookies in accordance with this policy and the <a href="#">Cookie Policy</a></p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">For what purpose is your Personal Information used?</h3>
                          <div>
                            <p>We use the Personal Information collected from you:</p>
                            <ul>
                              <li>To fulfill your requests (e.g. to send you news and updates and answers to your ques-tions), according to the forms you may fill.</li>
                              <li>To obtain your feedback (e.g. through a survey), in case you want to provide us with it.</li>
                              <li>To inform you by e-mail of our products and services, special offers and promotions, and any other marketing materials which we think may interest you.</li>
                              <li>To send you information regarding the Site such as changes to our terms, conditions, and policies and/or other administrative information.</li>
                              <li>For our internal business purposes, such as data analysis, audits, developing new prod-ucts, enhancing our website, improving our services and identifying usage trends. This information may be used as personal information and non-personal information.</li>
                              <li>To detect, prevent or otherwise address security or technical issues in connection with services provided through this Site.</li>
                            </ul>
                            <p>We may combine personal information collected from your navigation with the information provided by you through different channels (newsletters, specific campaigns, contests, etc.) obtaining a set of all your data provided to Amadeus. We will use this combined information to understand which are your preferences regarding the Amadeus services and products and to be able to provide you a targeted experience. We need to understand who are you and what your needs are. Having that knowledge we can offer you what you expect from us.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Unsubscribe</h3>
                          <div>
                            <p>If you change your mind, and no longer wish to receive marketing-related e-mails from us on a going-forward basis, you may unsubscribe from receiving such marketing-related e-mails or from such sharing at any time by using the tool provided to you.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Who is the Personal Information shared with?</h3>
                          <div>
                            <p>We may share Personal Information with Amadeus Affiliates, who, due to the nature of the services Amadeus provides as a global travel technology supplier, may be located in any location around the world. These Amadeus Affiliates may process Personal Information for the purposes described in this Privacy Policy. A link to the complete list of Amadeus Affiliates and their locations can be found <a href="#">here</a>.</p>
                            <p>In addition, we may also disclose your information to:</p>
                            <ul>
                              <li>Third parties, business partners, suppliers, subcontractors and service providers for the performance of any contract we enter into with them;</li>
                              <li>analytics and search engine providers that assist us in the improvement and optimiza-tion of our Site;</li>
                              <li>an affiliate or other third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including without limitation in connection with any bankruptcy or simi-lar proceedings); and</li>
                              <li>if necessary: (a) under applicable law including laws outside your country of residence; (b) to comply with legal processes; (c) to respond to requests from public and gov-ernment authorities including public and government authorities outside your country of residence;(d) to enforce our terms and conditions; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety or property, and/or that of our affiliates, you or others; and (g) to permit us to pursue available remedies or limit the damages that we may sustain.</li>
                            </ul>
                            <p>Where such disclosures take place Amadeus requires the appropriate technical and organizational security measures to be in place to protect personal data and for personal data to be processed lawfully.</p>
                            <p>Amadeus only allow affiliates and third party service providers to use personal data for specified purposes and in accordance with Amadeus instructions.</p>
                            <p>Any sharing of Personal Information using third party platforms would be governed by the terms of the third party platform used.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">International Transfer of Traveler Personal Data</h3>
                          <div>
                            <p>When Amadeus share your personal data with its affiliates and third party service providers who process personal data on behalf of Amadeus this will involve transferring personal data outside the EEA. When personal data is transferred to another country it will continue to receive adequate protection through contractual or other arrangements put in place with Affiliates and third party service providers. For these transfers at least one of the following appropriate safeguards will be implemented;</p>
                            <ul>
                              <li>Personal data will be transferred to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission;</li>
                              <li>Standard data protection clauses approved by the European Commission which give personal data transferred the same protection it has in EEA;</li>
                              <li>With affiliates and third party service providers based in the US, Privacy Shield which gives personal data similar protection it has in EEA;</li>
                            </ul>
                            <p>Further information on the appropriate safeguards used when transferring personal data outside the EEA can be requested through the contact details set out below in the section legal rights. When requesting this information please make a reference to the transfer of personal data outside the EEA.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Data Security and Integrity</h3>
                          <div>
                            <p>Amadeus has taken the appropriate technical and organizational security measures to protect personal data from loss or unlawful processing. When Personal Data is processed on behalf of Amadeus access is limited to those who have a business need to know, Personal Data will be processed in accordance with the instructions of Amadeus and those who have access are subject to a duty of confidentiality.</p>
                            <p>Amadeus have in place procedures to deal with any suspected personal data breach and will notify individuals and any applicable regulator of a breach where they are legally required to do so.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Data Retention</h3>
                          <div>
                            <p>Amadeus retains personal data for as long as necessary to fulfil the purposes it was collected for including for the purposes of satisfying any legal, accounting or reporting requirements.</p>
                            <p>Details of retention periods for different kinds of personal data can be found in specific privacy notices or are available upon request through the contact details below.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Legal rights</h3>
                          <div>
                            <p>Under certain circumstances individuals can exercise rights under data protection laws. Individuals can exercise these rights relating to their own personal data, or contact Amadeus for data protection related questions, by email to <a href="mailto:dataprotection@amadeus.com">dataprotection@amadeus.com</a> For the following rights please make a reference to the following in the request:or write to Chief Privacy Officer, Amadeus IT Group, S.A. C/Salvador de Madariaga 1, 28027 Madrid, Spain.</p>
                            <p>For the following rights please make a reference to the following in the request:</p>
                            <ul>
                              <li>Right to access Amadeus Business Partners – request for access to personal data –</li>
                              <li>Right to information Amadeus Business Partners -
                                <ul>
                                  <li>Amadeus affiliates and third party service providers who process personal data on behalf of Amadeus;</li>
                                  <li>transfers to third countries – information about data transfers outside EEA;</li>
                                </ul>
                              </li>
                              <li>Right to information Amadeus Business Partners – about retention periods of personal data;</li>
                            </ul>
                            <p>Amadeus will require authentication of the identity of the individual to exercise rights under data protection laws and may require additional information to assist in responding to requests.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Your rights</h3>
                          <div>
                            <p>Amadeus intends to carefully address any request and/or claim from you, as well as carefully process personal data. You are entitled to file any claim or complaint before the relevant data protection authorities, if the answer provided by Amadeus does not meet your expectations.</p>
                          </div>
                        </div>
                        <div className="mb-5">
                          <h3 className="text-primary">Updates</h3>
                          <div>
                            <p>This Privacy Notice is published by Amadeus IT Group SA. This Privacy Notice may be changed at any time. The date it was last updated is shown here April 30, 20.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <h3 className="text-primary"><a href="#">Privacy policies for Japan only available in Japanese​</a></h3>
                </div>
                <div className="mb-5">
                  <h3 className="text-primary"><a href="#">Privacy policies for Mexico only available in Spanish​</a></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
      <Footer />
    </div>
  );
};

export default withRouter(PrivacyPolicy);
