import React from 'react';

import './NotFound.scss';

export default () => {
  return (
    <div className="not-found-container">
      <h3>404 Not Found!</h3>
    </div>
  );
}