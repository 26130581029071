import React from 'react';
import classnames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import './Header.scss';

import amadeusWhiteImg from 'images/amadeus-icon-white.png';
import amadeusBlueImg from 'images/amadeus-icon-blue.png';
import homeIconWhiteImg from 'images/home-icon-white.png';
import homeIconBlueImg from 'images/home-icon-blue.png';
import Store from 'store';

interface Props {
  hasLoginButton?: boolean,
  hasIconHome?: boolean,
  rightColors: ["blue" | "white", "blue" | "white" | undefined, "blue" | "white" | undefined], // color of icons in the right,
  history: any,
  hideTextLeft?: boolean,
  loginLink?: string
}

export default withRouter((props : Props) => {
  return (
    <div className="app-header">
      <div className="site-name-left">
        {!props.hideTextLeft && 'Amadeus Payments Academy'}
      </div>
      <div className="logo-right">
        {props.hasLoginButton && (
          <Link to={!Store.User.isLoggedIn() ? (props.loginLink || "/login") : '/logout'} className={classnames("btn-login", props.rightColors[0] === "blue" ? "blue" : "")}>{!Store.User.isLoggedIn() ? 'Login/Register' : 'Hi ' + Store.User.userData.name + ' / Logout'}</Link>
        )}
        <img className="logo-right__amadeus" src={props.rightColors[0] === "blue" ? amadeusBlueImg : amadeusWhiteImg} alt="Amadeus" />
        {props.hasIconHome && (
          <img
            className="logo-right__home pointer"
            src={props.rightColors[1] === "blue" ? homeIconBlueImg : homeIconWhiteImg}
            alt="Home"
            onClick={() => props.history.push('/')}
          />
        )}
      </div>
    </div>
  );
});
