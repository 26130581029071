import React from 'react';
import Slider from 'react-slick';

import './HomeBannerSlider.scss';

import bannerImg from 'images/home-banner.png';
import iconImg from 'images/arrow-down-white.png';

const scrollToRef = (ref) => window.scrollTo({
  top: ref.current.offsetTop,
  left: 0,
  behavior: 'smooth'
});
export default (props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000
  };
  const slides = [
    {
      image: require('images/home-slider-8.jpg'),
      title: 'Welcome to the Amadeus Payments Academy',
      description: 'Enhance your knowledge and expertise of travel payments by enrolling in the Amadeus Payments Academy.',
      
    },
    {
      image: require('images/new-home-slider-testimonial.png'),
      title: 'Testimonial',
      description: '“On behalf of Qantas, we would like to extend our heart-felt thanks to you and the entire Amadeus Payments team. Fantastic event, definitely a star in the calendar. Looking forward to the evolutionary shift we will see this time next year!"',
      name: 'Christian Bowie',
      
    },
    {
      image: require('images/new-home-slider-testimonial-2.png'),
      title: 'Testimonial',
      description: '“When you are new in the field of payments, the Academy is the opportunity to get a deeper insight on how the sector is working.”',
      name: 'Laurenz Vietmeier',
      // name: 'Laurenz Vietmeier',
    }
  ];
  const myRef = React.useRef();
  const executeScroll = () => scrollToRef(myRef)
  return (
    <div className="home-banner-slider">
      <Slider {...settings}>
          {slides.map((slide, i) => (
            <div key={i} className="banner-slide">
              <div className="banner-slider-image" style={{ backgroundImage: `url(${slide.image})` }}></div>
              <div className="container">
                <div className="main-banner-slider">
                  <div className="row">
                    <div className="col-12 col-md-9">
                      <h1>{slide.title}</h1>
                      <h3>{slide.description}</h3>
                      <p>{slide.name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="arrow-down">
          <div className="text-center">
            <img className="icon-arrow" src={iconImg} onClick={executeScroll} />
          </div>
        </div>
        <div ref={myRef}></div>
    </div>
    
  );
};
