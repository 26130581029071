import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import ReactPlayer from 'react-player';
import Loader from 'react-loader-spinner';
import Slider from 'rc-slider';

import imgPlay from 'images/play-button.png';
import imgPause from 'images/pause.png';

import 'rc-slider/assets/index.css';
import './Video.scss';

interface Props {
  src: string,
  srcSub?: string,
  onPlay?(): void,
  onEnded?(): void,
}

const Video = (props: Props) => {

  const [state, setState] = useState({
    paused: false,
    autoPlay: false,
    speed: '100',
    loading: true,
    totalSec: 0,
    currentSec: 0,
    enableSubtitle: false,
  });

  const videoRef = useRef(null);
  const videoSubRef = useRef(null);

  const onReady = () => {
    setState({ ...state, loading: false });
  }

  const play = () => {
    videoRef.current.play();
  }

  const pause = () => {
    videoRef.current.pause();
  }

  const toggleSubtitle = () => {
    const enableSubtitle = !state.enableSubtitle;
    setState({ ...state, enableSubtitle });
    localStorage.setItem('enable_subtitle', String(enableSubtitle));
    if (enableSubtitle) {
      const currentTime = videoRef.current.currentTime;
      console.log('currentTime', currentTime);
      videoRef.current.pause();
      videoSubRef.current.currentTime = currentTime;
      videoSubRef.current.play();
    } else {
      const currentTime = videoSubRef.current.currentTime;
      console.log('currentTime', currentTime);
      videoSubRef.current.pause();
      videoRef.current.currentTime = currentTime;
      videoRef.current.play();
    }
  }

  const toggleAutoPlay = () => {
    const newValue = !state.autoPlay;
    setState({ ...state, autoPlay: newValue });
    localStorage.setItem('auto_play', String(newValue));
  }

  const onChangeSpeed = (e) => {
    const newSpeed = e.target.value;
    setState({ ...state, speed: newSpeed });
    videoRef.current.playbackRate = Number(newSpeed) / 100;
    if (videoSubRef.current) {
      videoSubRef.current.playbackRate = Number(newSpeed) / 100;
    }
    localStorage.setItem('video_speed', String(newSpeed));
  };

  const onEnded = () => {
    setState({ ...state, paused: true });
    !!props.onEnded && props.onEnded();
  }

  const onPlay = (e) => {
    !!props.onPlay && props.onPlay();
  }

  useEffect(() => {
    const speed = localStorage.getItem('video_speed') || '100';
    const autoPlay = localStorage.getItem('auto_play') === null || localStorage.getItem('auto_play') === 'false' ? false : true;
    const enableSubtitle = localStorage.getItem('enable_subtitle') === null || localStorage.getItem('enable_subtitle') === 'false' ? false : true;
    setState({ ...state, speed, autoPlay, paused: !autoPlay, enableSubtitle });
    videoRef.current.playbackRate = Number(speed) / 100;
    if (videoSubRef.current) {
      videoSubRef.current.playbackRate = Number(speed) / 100;
    }
  }, []);

  return (
    <>
      <div className="video-container">
        {state.loading && (
          <div className="loading">
            <Loader
              type="Oval"
              color="#0072bc"
              height={50}
              width={50}
            />
          </div>
        )}
        <div className="absolute-fill">
          {state.loading ? (
            <ReactPlayer url={props.src} ref={videoRef} onReady={onReady} />
          ):(
            <>
            <div className="absolute-fill" style={{ zIndex: state.enableSubtitle ? 1 : 2 }}>
              <video
                autoPlay={!state.enableSubtitle ? state.autoPlay : false}
                controls
                ref={videoRef}
                onPlay={!state.enableSubtitle ? onPlay : undefined}
                onEnded={!state.enableSubtitle ? onEnded : undefined}
              >
                <source src={props.src} type="video/mp4" />
              </video>
            </div>
            {props.srcSub && (
              <div className="absolute-fill" style={{ zIndex: state.enableSubtitle ? 2 : 1 }}>
                <video
                  autoPlay={state.enableSubtitle ? state.autoPlay : false}
                  controls
                  ref={videoSubRef}
                  onPlay={state.enableSubtitle ? onPlay : undefined}
                  onEnded={state.enableSubtitle ? onEnded : undefined}
                >
                  <source src={props.srcSub} type="video/mp4" />
                </video>
              </div>
            )}
            </>
          )}
        </div>
        {/* <div className="absolute-fill middle">
          {state.paused &&
            <div className="button-container">
              <img
                className="play-icon pointer"
                src={imgPlay}
                alt="Play"
                onClick={() => {
                  setState({ ...state, paused: false });
                  play();
                }}
              />
            </div>}
          {!state.paused &&
            <div className="button-container">
              <img
                className="pause-icon pointer"
                src={imgPause}
                alt="Pause"
                onClick={() => {
                  setState({ ...state, paused: true });
                  pause();
                }}
              />
            </div>}
        </div> */}
      </div>
      
      <div className="video-setting">

        {props.srcSub &&
        <div className="video-setting__auto-play">
          Subtitles
          <div className="video-setting__auto-play__switch pointer" onClick={toggleSubtitle}>
            <div className={classnames("video-setting__auto-play__thumb", state.enableSubtitle ? 'active' : '')} />
          </div>
        </div>}

        <div className="video-setting__auto-play">
          Auto play
          <div className="video-setting__auto-play__switch pointer" onClick={toggleAutoPlay}>
            <div className={classnames("video-setting__auto-play__thumb", state.autoPlay ? 'active' : '')} />
          </div>
        </div>

        <div className="video-setting__speed">
          Play speed
          <select value={state.speed} onChange={onChangeSpeed}>
            <option value="100">100%</option>
            <option value="150">150%</option>
            <option value="75">75%</option>
            <option value="50">50%</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Video;