import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Store from 'store';
import { Modal } from 'components';
import Survey from './Survey';
import './Quiz.scss';

// import loadingImg from './loading.gif';

import loadingImg from 'images/ajax-loader.gif';

import bgQuizzError from 'images/bg-quizz-e.jpg';
import bgQuizzCert from 'images/bg-quizz-cert.jpg';
import bgQuizzNext from 'images/bg-quizz-next.jpg';

interface Props {
  content: any,
  onComplete?(): any,
  onNext?(): any,
  getNextModuleInfo: any,
  isCompletedAll: boolean,
}

let _fetchIntervel;
let willAutoDownloadCertificate = false;

const Quiz = (props: Props) => {

  const [nextStep, setNextStep] : any = useState({});

  const [state, setState] = useState({
    currentNumber: '1',
    currentAnswers: {},
    passed: false,
    passedMessage: '',
    showModalMessage: false,
    modalData: {
      score: '',
      isPass: false,
      isPassAll: false,
      message: '',
    },
    completedSurvey: false,
    certificateUrl: '',
    surveyData: {
      q1: '',
      q2: '',
      q3: '',
    }
  });

  const [userName, setUserName] = useState('');
  const [blankNameState, setBlankNameState] = useState({
    isLoading: false,
  })

  const [submitLoading, setSubmitLoading] = useState(false);

  const currentData = props.content.quiz[+state.currentNumber - 1];

  const downloadBlank = () => {
    // console.log('download here')
    if (!userName) {
      alert('Please input your name');
      return;
    }
    console.log('state.certificateUrl', state.certificateUrl);
    if (state.certificateUrl) return;
    // console.log('setBlankNameState')
    setBlankNameState({
      ...blankNameState,
      isLoading: true,
    });
    // console.log('submitQuizToServer');
    Store.User.submitQuizToServer(true, userName);
    // console.log('here');
    willAutoDownloadCertificate = true;
  };

  const changeCurrentNumber = (newNumber) => {
    setState({
      ...state,
      // currentNumber: state.currentNumber == props.content.quiz.length ? String(+state.currentNumber - 1) : String(+state.currentNumber + 1)
      currentNumber: newNumber,
    })
  };

  const prev = () => {
    changeCurrentNumber(state.currentNumber === '1' ? '1' : String(+state.currentNumber - 1));
  };

  const next = () => {
    changeCurrentNumber(state.currentNumber == props.content.quiz.length ? state.currentNumber : String(+state.currentNumber + 1));
  };

  const onSubmitSurvey = async ({ q1, q2, q3 }) => {
    setState({
      ...state,
      surveyData: { q1, q2, q3 },
    });
    const [res, err] = await Store.User.getProgressQuiz();
    if (err) return alert(err);
    if (!res.success) return alert(res.error);
    if (res.data) {
      const newState = { ...state, surveyData: { q1, q2, q3 }, completedSurvey: true, certificateUrl: res.data.certificate };
      setState(newState);
      if (!res.data.certificate) {
        keepFetchingUntilTheCertificateIsGenerated(newState);
      } 
    }
  }

  const keepFetchingUntilTheCertificateIsGenerated = async (newState) => {
    _fetchIntervel = setInterval(async () => {
      const [res, err] = await Store.User.getProgressQuiz();
      if (err) return;
      if (!res.success) return;
      if (res.data && res.data.certificate) {
        setState({ ...newState, certificateUrl: res.data.certificate });
        setBlankNameState({
          ...blankNameState,
          isLoading: false,
        });
        clearInterval(_fetchIntervel);
        if (willAutoDownloadCertificate) {
          const link = document.createElement('a');
          link.href = res.data.certificate;
          link.setAttribute('download', `certificate_${userName}.pdf`);
          document.body.appendChild(link);
          link.click();
        }
      }
    }, 1000);
    
  }
  
  const submit = async () => {
    if (submitLoading) return;
    const { message, isPassed, score, isPassedAll, nextStep : nextStepRes } = Store.User.checkResultQuiz(props.content.title);
    console.log({ message, isPassed, score, isPassedAll, nextStepRes });
    if (nextStepRes) {
      setNextStep(nextStepRes);
    }
    if (!isPassed) return setState({
      ...state,
      showModalMessage: true,
      modalData: {
        score,
        isPass: false,
        isPassAll: false,
        message,
      }
    })
    if (isPassedAll) {
      if (Store.User.userData.name) {
        // only generate certificate when user already has name
        setSubmitLoading(true);
        await Store.User.submitQuizToServer(isPassedAll);
        setSubmitLoading(false);
      }
    } else {
      Store.User.submitQuizToServer(isPassedAll);
    }
    setState({
      ...state,
      passed: true,
      passedMessage: message,
      showModalMessage: true,
      modalData: {
        score,
        isPass: true,
        isPassAll: Boolean(isPassedAll),
        message: '',
      }
    });
    !!props.onComplete && props.onComplete();
  };

  // @ts-ignore
  window.showModal = () => {
    setState({
      ...state,
      passed: true,
      showModalMessage: true,
      modalData: {
        score: '',
        isPass: true,
        isPassAll: true,
        message: '',
      }
    });
  }

  useEffect(() => {
    const answer = Store.User.getAnswer(props.content.title, state.currentNumber);
    setState({
      ...state,
      currentAnswers: {
        ...state.currentAnswers,
        [state.currentNumber]: answer,
      }
    });
  }, [state.currentNumber]);

  useEffect(() => {
    const { isPassed } = Store.User.checkResultQuiz(props.content.title);
    setState({
      ...state,
      passed: isPassed,
    })
  }, []);

  if (props.isCompletedAll ) {
    return (
      <div className="lv1-quiz message-middle">
        <h4 className="lv1-quiz__q-a__quiz-number">{state.passedMessage || 'You have completed this quiz.'}</h4>
        {!props.isCompletedAll && <h4 className="lv1-quiz__q-a__quiz-number pointer" onClick={props.onNext}><u>Next Module</u></h4>}
        <Modal
          open={state.showModalMessage}
          onClose={() => setState({ ...state, showModalMessage: false })}
          styles={{
            modal: {
              backgroundColor: 'var(--color-main)',
              maxWidth: '70vw',
              padding: 0,
            },
            closeButton: {
              display: 'block',
              fill: '#FFFFFF',
              borderRadius: '50%',
              border: '1px solid #FFFFFF',
              width: '30px',
              height: '30px',
              outline: 'none'
            }
          }}
        >
          {state.modalData.isPass ? (
            <div className="quiz-message-modal">
              <h3>{state.passedMessage}</h3>
              <button onClick={props.onNext}>Next Module</button>
            </div>
          ) : (
            <div className="quiz-message-modal">
              <h1 className="mb-0">Oops! Try again</h1>
              <h3>You scored {state.modalData.score}</h3>
              <h4>You have to answer 4 questions correctly to pass this module. Do not worry, just try again and continue</h4>
              <button onClick={() => setState({ ...state, showModalMessage: false })}>Try again</button>
            </div>
          )}
        </Modal>
      </div>
    )
  }

  return (
    <div className="lv1-quiz">
      <div className="lv1-quiz__q-a">
        <h4 className="lv1-quiz__q-a__quiz-number"><strong>Question {state.currentNumber}</strong> of {props.content.quiz.length}</h4>
        <p className="lv1-quiz__q-a__question">{currentData.question}</p>
        {currentData.answers.map((val, i) => (
          <label className="lv1-quiz__q-a__answer" key={"item-"+i}>
            <input
              type="checkbox"
              checked={state.currentAnswers[state.currentNumber] === val.value}
              onChange={e => {
                setState({
                  ...state,
                  currentAnswers: {
                    ...state.currentAnswers,
                    [state.currentNumber]: val.value,
                  }
                });
                Store.User.answerQuestion(props.content.title, state.currentNumber, val.value);
              }}
            />
            {val.label}
          </label>
        ))}
      </div>
      <div className="lv1-quiz__btns">
        {(state.currentNumber !== '1') && (
          <div className={classnames("btn mr-2", state.currentNumber === '1' ? '' : 'active')} onClick={prev}>
            Prev
          </div>
        )}
        
        {+state.currentNumber !== props.content.quiz.length &&
        <div className={classnames("btn mr-2", 'active')} onClick={next}>
          Next
        </div>}
        {Object.keys(state.currentAnswers).length === props.content.quiz.length &&
        <div className="btn active" onClick={submit}>
          {submitLoading ? 'Submitting...' : 'Submit'}
        </div>}
      </div>
      {state.modalData.isPassAll ? (
        <Modal
          open={state.showModalMessage}
          onClose={() => setState({ ...state, showModalMessage: false })}
          styles={{
            modal: {
              backgroundColor: 'var(--color-main)',
              maxWidth: '70vw',
              padding: 0,
            },
            closeButton: {
              display: 'block',
              fill: '#FFFFFF',
              borderRadius: '50%',
              border: '1px solid #FFFFFF',
              width: '30px',
              height: '30px',
              outline: 'none'
            }
          }}
        >
          {!state.completedSurvey ? (
            <Survey
              onSubmit={onSubmitSurvey}
            />
          ) : (
            <div className="quiz-message-modal">
              <h1 className="mb-0">Thank you!</h1>
              <h3 className="mb-5" style={{ transform: 'translateX(6px)' }}>For completing Level 1</h3>
              {Store.User.userData.name ? (
                <>
                  <h3 className="mb-2 text-center">You can download your certificate of completion and add a badge to your LinkedIn page by clicking on the buttons below</h3>
                  <div className="d-flex align-items-center justify-content-center">
                    <button className="white-btn text-center mr-5"><a href={state.certificateUrl} target="_blank">{!state.certificateUrl ? 'Generating your certificate, please wait..' : 'Download Certificate'}</a></button>
                    <button className="white-btn text-center ml-5"><a href={`https://www.linkedin.com/profile/add?startTask=${Store.User.userData.name}`} target="_blank">Add LinkedIn Badge</a></button>
                  </div>
                </>
              ) : (
                <>
                  <h3 className="mb-2 text-center">Please type your name in the box below so we can personalize your certificate of completion. This may take a few minutes. Also, click the button to add a badge to your LinkedIn page.</h3>
                  <div style={{ width: '50%', marginTop: 20, }}>
                    <h4 style={{ fontSize: 20, textAlign: 'center' }}>Name and Surname</h4>
                    <input className="input input-group input-control" style={{ width: '100%' }} value={userName} onChange={e => setUserName(e.target.value)} />
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <button className="white-btn text-center mr-5" onClick={!state.certificateUrl ? downloadBlank : undefined}>
                      {!state.certificateUrl ? (
                        blankNameState.isLoading ? (
                          <>
                            <img src={loadingImg} alt="Loading" className="mr-1" style={{ height: 15, width: 'auto', marginRight: 5 }} />
                            <span>Generating your certificate..</span>
                          </>
                        ) : (
                          <span>Download your certificate</span>
                        )
                      ) : (
                        <a href={state.certificateUrl} target="_blank">Download your certificate</a>
                      )}
                    </button>
                    <button className="white-btn text-center ml-5"><a href={`https://www.linkedin.com/profile/add?startTask=${Store.User.userData.name}`} target="_blank">Add LinkedIn badge</a></button>
                  </div>
                </>
              )}
              
            </div>
          ) } 
        </Modal>
      ) : (
        <div className={classnames("quiz-message-overlay", state.showModalMessage ? 'open' : '')}>
          {state.modalData.isPassAll ? (
            <div
              className="quiz-message-modal w-100 h-100"
              style={{
                background: `url("${bgQuizzCert}") center center /cover no-repeat`,
              }}
            >
              <div className="quiz-message-modal-inner-content-overlay">
                <h1 className="mb-0">Congratulations!</h1>
                <h3 className="mb-5">You completed level 1</h3>
                <h3 className="text-center">Your certificate will be sent to your registered email</h3>
              </div>
            </div>
          ) : state.modalData.isPass ? (
            <div
              className="quiz-message-modal w-100 h-100"
              style={{
                background: `url("${bgQuizzNext}") center center /cover no-repeat`,
              }}
            >
              <div className="quiz-message-modal-inner-content-overlay">
                <h1 className="mb-0">Congratulations!</h1>
                <h3 className="text-center">{state.passedMessage}</h3>
                <h4 className="text-center">Next Step: {nextStep.title}</h4>
                <p className="text-center" style={{ marginBottom: 0, fontSize: '1.2em' }}>{nextStep.nextStepText}</p>
                <button onClick={props.onNext}>Next Step</button>
              </div>
            </div>
          ) : (
            <div
              className="quiz-message-modal w-100 h-100"
              style={{
                background: `url("${bgQuizzError}") center center /cover no-repeat`,
              }}
            >
              <div className="quiz-message-modal-inner-content-overlay">
                <h1 className="mb-0">Oops! Try again</h1>
                {state.modalData.score && <h3 className="text-center">You scored {state.modalData.score || state.modalData.message}</h3>}
                <h4 className="text-center">You have to answer 4 questions correctly to pass this module. Do not worry, just try again and continue</h4>
                <button onClick={() => setState({ ...state, showModalMessage: false, currentNumber: '1' })}>Try again</button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Quiz;
