import React, { Fragment, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import './LevelOneSidebar.scss';

import tickImg from 'images/tick.png';
import leftChevronImg from 'images/left-chevron.png';
import rightChevronImg from 'images/right-chevron.png';

interface Props {
  data: object,
  completed: Array<string>,
  focus: string,
  onChangeFocus(string): void,
  onToggleIconClick(): void,
  isToggled: boolean,
  onClickInstruction?(): any,
}

const LevelOneSidebar = (props: Props) => {

  const isCompleted = (name) =>props.completed.includes(name)

  return (
    <div className="lv1-side-bar">
      <div className="lv1-side-bar__intro" onClick={props.onClickInstruction}>
        <h3>Instructions</h3>
      </div>
      <div className="lv1-side-bar__progress-container">
        
        {Object.keys(props.data).map((val, i, arr) => {

          const isUpperVerticalLineBlue = i === 0 ? false : isCompleted(arr[i]) && isCompleted(arr[i-1]);
          const isLowerVerticalLineBlue = i === arr.length - 1 ? false : isCompleted(arr[i]) && isCompleted(arr[i+1]);

          return (
            <Fragment key={"item-"+i}>
              <div className={classnames("milestone", "pointer", "index-"+i)} onClick={() => props.onChangeFocus(val)}>
                <div className="milestone__circle__wrapper">
                  <div className="milestone__vertical-line__upper">
                    <div className={isUpperVerticalLineBlue ? 'blue' : ''} />
                  </div>
                  <div className={classnames("milestone__circle", props.focus === val || isCompleted(val) ? 'focused' : '')}>
                    {isCompleted(val) && <img src={tickImg} alt={val} className="milestone__tick" />}
                  </div>
                  <div className="milestone__vertical-line__lower">
                    <div className={isLowerVerticalLineBlue ? 'blue' : ''} />
                  </div>
                </div>
                <span className={classnames("milestone__title", props.focus === val ? 'focused' : '')}>{val}</span>
              </div>
              {Boolean(i !== arr.length -1) &&
              <div className="milestone__vertical-line">
                <div className={isLowerVerticalLineBlue ? 'blue' : ''} />
              </div>}
            </Fragment>
          );
        })}
      </div>
      <div className="lv1-side-bar__footer">© Amadeus IT Group SA</div>
      <div className="lv1-side-bar__toggle-icon" onClick={props.onToggleIconClick}>
        <img src={props.isToggled ? leftChevronImg : rightChevronImg} alt="Toggle" />
        <span>{props.isToggled ? 'Collapse' : 'Expand'}</span>
      </div>
    </div>
  );
};

export default LevelOneSidebar;
