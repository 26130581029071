import React, { useState } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';

import './HomeSlider.scss';

export default (props) => {
  const [state, setState] = useState({
    companyActive: 0
  });
  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    // responsive: [
    //   {
    //     breakpoint: 1200,
    //     settings: {
    //       slidesToShow: 5,
    //       slidesToScroll: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 575,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };
  const companies = [
    {
      image: require('images/home-slider-1.png'),
      testimonial: 'Thanks to the Amadeus Team, it was a very successful experience and I hope to attend level 2 and in the future level 3.',
      name: 'Zineb Rabouh',
    },
    {
      image: require('images/home-slider-2.png'),
      testimonial: 'When you are new in the field of payments, the Academy is the opportunity to get a deeper insight on how the sector is working.',
      name: 'Laurenz Vietmeier',
      
    },
    {
      image: require('images/home-slider-3.png'),
      testimonial: 'It was a fantastic experience. The training included a lot of information that will definitely help us to enhance our services and get to the next level in payments.',
      name: 'Mohammad Bakr Serhan',
    },
    {
      image: require('images/home-slider-4.png'),
      testimonial: 'On behalf of Qantas, we’d like to extend our heart-felt thanks to you and the entire Amadeus Payments team. Fantastic event, definitely a star in the calendar. Looking forward to the evolutionary shift we will see this time next year!',
      name: 'Christian Bowie',
      
    },
    {
      image: require('images/home-slider-5.png'),
      testimonial: 'It is a good start into the payments industry, gives you a great introduction of basic concepts and flows. Great experience.',
      name: 'Johan Kitchenin',
    },
    {
      image: require('images/home-slider-7.png'),
      testimonial: 'Good introduction and the trainer is very knowledgeable. I will definitely promote and recommend this training to my colleagues.',
      name: 'Yasunori Yamaguchi',
    }
  ];
  const handleSliderClick = id => {
    setState({ companyActive: id });
  };
  return (
    <div className="home-slider">
      <div className="container">
        <Slider {...settings}>
          {companies.map((company, i) => (
            <div key={i} className="slider-image-comtainer">
              <div className={classnames("slider-image", "testimonial-section", (state.companyActive == i) ? 'active' : '')} onClick={() => handleSliderClick(i)}>
                {/* <img src={company.image} alt="" /> */}
                <h3 key={i} className={classnames("testimonial")}>“{company.testimonial}”</h3>
                <p className="text-center">- {company.name} -</p>
              </div>
            </div>
          ))}
        </Slider>
        {/* <div className="testimonial-section">
          {companies.map((company, i) => (
            <h3 key={i} className={classnames("", (state.companyActive == i) ? 'active' : '')}>“{company.testimonial}”</h3>
          ))}
        </div> */}
      </div>
    </div>
  );
};
