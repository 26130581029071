import Request from './Request.Utils';
import StoreClass from './StoreClass.Utils';
import erria from 'erria/decorator';
import { LEVEL_1 } from 'data';

class User extends StoreClass {
  constructor() {
    super();
    this.getInitrialData();
  }

  userData : any = {};
  quizData : any = {};

  /*
  quizData = {
    Introduction: {
      answers: {
        1: 'a',
        2: 'b',
      },
      isCompleted: false
    },
    ...
  }
  */

  async getInitrialData() {
    const userData = await this.persist.sync('user_data', 'object');
    if (userData) this.userData = userData;
    if (userData && userData.token) {
      Request.setToken(userData.token);
    }
    const quizData = await this.persist.sync('quiz_data', 'object');
    if (quizData) this.quizData = quizData;
    this.makeReady();
  }

  @erria
  async login({ email, password }) {
    const [res, err] = await Request.post('/users/login', { email, password });
    if (err) throw err;
    if (res.data.success) {
      this.userData = {
        ...res.data.data.user,
        token: res.data.data.token,
      };
      this.persist.save(this.userData, 'user_data');
      Request.setToken(res.data.data.token);
    }
    return res.data;
  }

  logout() {
    Request.setToken(undefined);
    this.userData = {};
    this.persist.save(this.userData, 'user_data');
    localStorage.clear(); // just to be sure
  }

  @erria
  async register({ email, password, name, company, industry, country }) {
    const [res, err] = await Request.post('/users/register', { email, password, name, company, industry, country });
    if (err) throw err;
    if (res.data.success) {
      this.userData = {
        ...res.data.data.user,
        token: res.data.data.token,
      };
      this.persist.save(this.userData, 'user_data');
      Request.setToken(res.data.data.token);
    }
    return res.data;
  }

  isLoggedIn() {
    return !!this.userData.token;
  }

  // @erria
  // async checkCompletedQuiz() {
  //   const [res, err] = await Request.get('/level/check-completed-level-1', {});
  //   if (err) throw err;
  //   return res.data;
  // }

  @erria
  async getProgressQuiz() {
    const [res, err] = await Request.get('/level/get-progress-level-1', {});
    if (err) throw err;
    return res.data;
  }

  saveQuizData(data) {
    this.quizData = data;
    this.persist.save(this.quizData, 'quiz_data');
  }

  answerQuestion(quizName, questionNumber, answer) {
    const findQuestionInData = LEVEL_1[quizName].quiz.find(val => val.number === questionNumber);
    if (!findQuestionInData) return;
    // const isCorrect = answer === findQuestionInData.correct;
    if (!this.quizData[quizName]) this.quizData[quizName] = { answers: {}, isCompleted: false };
    this.quizData[quizName].answers[questionNumber] = answer;
    this.saveQuizData(this.quizData);
  }

  getCompletedQuiz() {
    let completeds = [];
    for (let key in this.quizData) {
      if (!!this.quizData[key] && this.quizData[key].isCompleted) {
        completeds.push(key);
      }
    }
    return completeds;
  }

  getAnswer(quizName, questionNumber) {
    return !this.quizData[quizName] || !this.quizData[quizName].answers ? undefined : this.quizData[quizName].answers[questionNumber];
  }

  getNextStepAfter(quizName) {
    const titleArr = Object.keys(LEVEL_1);
    const index = titleArr.indexOf(quizName);
    const nextIndex = index === titleArr.length - 1 ? 0 : index + 1;
    return LEVEL_1[titleArr[nextIndex]];
  }

  checkResultQuiz(quizName) {
    if (LEVEL_1[quizName].quiz.length === 0) {
      this.quizData[quizName] = {
        answers: {},
        isCompleted: true,
      };
      this.saveQuizData(this.quizData);
      return { message: '', isPassed: true, score: ''  };
    }
    if (!this.quizData[quizName]) return { message: 'You have not done this quiz before', isPassed: false, score: '' };
    const didAnswerAll = LEVEL_1[quizName].quiz.length === Object.keys(this.quizData[quizName].answers).length;
    if (!didAnswerAll) return { message: 'You must answer all of the questions to progress to the next module', isPassed: false, score: '' };

    let numberOfCorrectAnswers = 0;

    for (let questionNumber in this.quizData[quizName].answers) {
      const findQuestionInData = LEVEL_1[quizName].quiz.find(val => val.number === questionNumber);
      if (!findQuestionInData) return { message: 'Something went wrong, please reload and do it again', isPassed: false, score: '' };
      if (this.quizData[quizName].answers[questionNumber] === findQuestionInData.correct) {
        numberOfCorrectAnswers++;
      }
    }

    if (numberOfCorrectAnswers >= LEVEL_1[quizName].quiz.length - 1) {
      this.quizData[quizName].isCompleted = true;
      this.saveQuizData(this.quizData);
      return { message: `You have correctly answered ${numberOfCorrectAnswers}/${LEVEL_1[quizName].quiz.length}`, isPassed: true, isPassedAll: this.checkAllQuizzesCompleted(), nextStep: this.getNextStepAfter(quizName), };
    }
    return { message: `You have correctly answered ${numberOfCorrectAnswers}/${LEVEL_1[quizName].quiz.length}, please do it again and make sure there are no more than one incorrect answer.`, isPassed: false, score: `${numberOfCorrectAnswers}/${LEVEL_1[quizName].quiz.length}` };
  }

  checkAllQuizzesCompleted() {
    let numberOfCompleted = 0;
    for (let quizName in this.quizData) {
      if (this.quizData[quizName].isCompleted) numberOfCompleted++;
    }
    return numberOfCompleted === Object.keys(LEVEL_1).length;
  }

  @erria
  async submitQuizToServer(isCompleted, name = this.userData.name) {
    const [res, err] = await Request.post('/level/quiz', { name, data: JSON.stringify(this.quizData), isCompleted: Boolean(isCompleted) });
    if (err) throw err;
    return res.data;
  }

  @erria
  async submitSurveyToServer(data) {
    const [res, err] = await Request.post('/level/quiz/survey', { data: JSON.stringify(data) });
    if (err) throw err;
    return res.data;
  }

  @erria
  async submitLevel23({ fullName, companyName, email, phone, industry, country, eventLocation, eventTime, eventDescription, level, question }) {
    const [res, err] = await Request.post('/level/register-event', { fullName, companyName, email, phone, industry, country, eventLocation, eventTime, eventDescription, level, question });
    if (err) throw err;
    return res.data;
  }

  @erria
  async forgotPassword({ email }){
    const [res, err] = await Request.post('/users/forgot-password', { email });
    if (err) throw err;
    return res.data;
  }

  @erria
  async updatePassword({ email, resetCode, newPassword }){
    const [res, err] = await Request.post('/users/update-password', { email, resetCode, newPassword });
    if (err) throw err;
    return res.data;
  }

  @erria
  async getCertificateData(code){
    const [res, err] = await Request.post('/level/get-certificate-data', { code });
    if (err) throw err;
    return res.data;
  }
}

export default new User();