import React from 'react';

import './Benefits.scss';

import boxImg1 from 'images/benefit-1.svg';
import boxImg2 from 'images/benefit-2.svg';
import boxImg3 from 'images/benefit-3.svg';

export default (props) => {
  return (
    <div className="benefit-section">
      <div className="container">
        <div className="benefit-heading">
          <h3>Who can benefit from the payments academy?</h3>
        </div>
      	<div className="row">
      		<div className="col-12 col-sm-4">
      			<div className="benefit-item">
              <div className="benefit-icon">
                <img src={boxImg1} alt="" />
              </div>
              <h4>New payments learners</h4>
              <p>Someone who’s just started in payments and requires a basic understanding of the industry, its players and the overall landscape</p>
            </div>
      		</div>
          <div className="col-12 col-sm-4">
            <div className="benefit-item">
              <div className="benefit-icon">
                <img src={boxImg2} alt="" />
              </div>
              <h4>Payments improvers</h4>
              <p>Someone who has been in payments for a while and learnt about the industry by other generic sources of information, by internal training or by simply doing the job (less than 5 years in travel payments)</p>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="benefit-item">
              <div className="benefit-icon">
                <img src={boxImg3} alt="" />
              </div>
              <h4>Payments proficient</h4>
              <p>Someone that has a good understanding of the travel payments industry but needs training in specific areas</p>
            </div>
          </div>
      	</div>
      </div>
    </div>
  );
};
