import React, { useState, useEffect } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import Store from 'store';
import { BackgroundContainer, Container, Header, Modal } from 'components';

import './Login.scss';
import bgImg from 'images/bg-1.jpg';
import bgImgLv23 from 'images/large-background.png';

const qs = require('qs');

const Login = (props) => {

  const query = qs.parse(props.location.search.replace('?', ''));

  const [state, setState] = useState({
    email: '',
    password: '',
    showRegisterModal: false,
    registerName: '',
    registerEmail: '',
    registerCompany: '',
    registerIndustry: '',
    registerCountry: '',
    registerPassword: '',
    registerPassswordConfirm: '',
    policyCheck: false,
    redirect: (() => {
      if (window.location.href === 'https://986840academy.com/login?redirect=/level-1-training&utm_campaign=PAY%20Payments%20Academy%20Registration%20EML%20202006&utm_medium=email&utm_source=Eloqua') return '/';
      return query.redirect;
    })(),
    showForgotPasswordModal: false,
    showSuccessModal: false,
    hasResetCode: false,
    forgotEmail: '',
    newPassword: '',
    resetCode: '',
    showSuccessUpdatePasswordText: false,
    isNotLevel1: !query.redirect || !query.redirect.includes("/level-1"),
  });

  const changeState = (label) => (e) =>  {
    setState({ ...state, [label]: !!e.target ? e.target.value : e })
  }

  if (window.location.href === 'https://986840academy.com/login?redirect=/level-1-training&utm_campaign=PAY%20Payments%20Academy%20Registration%20EML%20202006&utm_medium=email&utm_source=Eloqua') {
    return <Redirect to={"/"} />
  }

  if (Store.User.isLoggedIn()) {
    return <Redirect to={state.redirect || "/"} />
  }

  const onLogin = async e => {
    e.preventDefault();
    const { email, password } = state;
    if (!email || !password) return alert('Please check your form inputs');
    const [res, err] = await Store.User.login({ email, password });
    if (err) alert(String(err));
    if (res.error) return alert(res.error);
    console.log(res);
    props.history.push(state.redirect || '/')
  };

  const onRegister = async e => {
    e.preventDefault();
    const { registerPassword, registerPassswordConfirm, registerEmail, registerCompany, registerIndustry, registerCountry, registerName, policyCheck } = state;
    // if (!policyCheck) return alert('Please make sure you have read the policy before continue');
    if (!registerPassword || !registerPassswordConfirm || !registerEmail || !registerName) return alert('Please check your form inputs');
    if (registerPassword !== registerPassswordConfirm) return alert('Passwords dont match');
    const [res, err] = await Store.User.register({
      email: registerEmail,
      password: registerPassword,
      name: registerName,
      company: registerCompany,
      industry: registerIndustry,
      country: registerCountry,
    });
    if (res.error) return alert(res.error);
    if (err) alert(String(err));
    console.log(res);
    props.history.push(state.redirect || '/')
  };

  const onForgotPassword = async e => {
    e.preventDefault();
    const { forgotEmail } = state;
    if (!forgotEmail) return alert('Please check email input.');
    const [res, err] = await Store.User.forgotPassword({ email: forgotEmail });
    if (err) alert(String(err));
    if (res.error) return alert(res.error);
    setState({
      ...state,
      hasResetCode: true,
    });
  };

  const onUpdatePassword = async e => {
    e.preventDefault();
    const { forgotEmail, resetCode, newPassword } = state;
    if (!forgotEmail || !resetCode || !newPassword) return alert('Please check your inputs.');
    const [res, err] = await Store.User.updatePassword({ email: forgotEmail, resetCode, newPassword });
    if (err) alert(String(err));
    if (res.error) return alert(res.error);
    setState({
      ...state,
      showSuccessModal: true,
      showSuccessUpdatePasswordText: true,
      showForgotPasswordModal: false,
    });
  }

  console.log('state.redirect', state.redirect);
  
  return (
    <BackgroundContainer className="login-page" img={state.isNotLevel1 ? bgImgLv23 : bgImg} withGreyGradientOverlay>
      <Container>
        <Header
          hasIconHome
          rightColors={[state.isNotLevel1 ? "white" : "blue", "white"]}
        />
        <div className="row main-content">
          <div className="col-12 col-md-4 heading">
            {state.isNotLevel1 ? (
              <>
                <h1>Welcome to the</h1>
                <h1>Amadeus Payments Academy</h1>
                <h4>Enhance your knowledge and expertise of travel payments</h4>
                <h4>by enrolling in the Amadeus Payments Academy.</h4>
              </>
            ) : (
              <>
                <h1>Certification Level 1:</h1>
                <h1>Free online training</h1>
                <ul>
                  <li>Learn about payments fundamentals</li>
                  <li>Discover top-line information about the payments industry. <br />Learn about what an issuer is, what an acquirer is, what the roles are of PSPs, schemes, regulators and what are business models.</li>
                </ul>
              </>
            )}
            
            
          </div>
          <div className="col-12 col-md-4 offset-md-4 login-form d-flex justify-content-center">
            <form className="form" onSubmit={e => { e.preventDefault(); changeState('showRegisterModal')(true) }}>
              <h4>Create a Login</h4>
              <p style={{ color: '#535353', fontSize: 20 }}>To access the training and track your progress, please create a login using your email address.</p>
              <input type="submit" value="Create a Login →" />
            </form>
            
          </div>
          <div className="col-12 col-md-4 login-form">
            <form className="form" onSubmit={onLogin}>
              <h4>Login</h4>
              <input placeholder="Email" type="text" value={state.email} onChange={e => setState({ ...state, email: e.target.value })} />
              <input placeholder="Password" type="password" autoComplete="off" value={state.password} onChange={e => setState({ ...state, password: e.target.value })} />
              <p><a href="#" onClick={() => changeState('showForgotPasswordModal')(true)}>Forgotten your password?</a></p>
              <input type="submit" value="Sign in →" />
            </form>

            {/* <div className="register-now pointer" onClick={() => changeState('showRegisterModal')(true)}>
              New user? Register now!
            </div> */}
          </div>
        </div>
        <div className="footer-copyright">
          © Amadeus IT Group SA
          <a href="https://amadeus.com/en/policies/amadeus-policies" target="_blank">Legal Notices & Privacy</a>
        </div>
      </Container>
      <Modal open={state.showRegisterModal} onClose={() => changeState('showRegisterModal')(false)}>
        <form className="form register-form" onSubmit={onRegister}>
          <h4>Register</h4>
          <input placeholder="Full Name" type="text" value={state.registerName} onChange={e => setState({ ...state, registerName: e.target.value })} />
          <input placeholder="Email" type="text" name="email" value={state.registerEmail} onChange={e => setState({ ...state, registerEmail: e.target.value })} />
          {/* <input placeholder="Company" type="text" value={state.registerCompany} onChange={e => setState({ ...state, registerCompany: e.target.value })} />
          <select onChange={e => setState({ ...state, registerIndustry: e.target.value })}>
            <option value="" disabled selected>Select industry</option>
            <option value="Airline">Airline</option>
            <option value="Travel Agent">Travel Agent</option>
            <option value="Hospitality">Hospitality</option>
            <option value="Other">Other</option>
          </select>
          <input placeholder="Country" type="text" value={state.registerCountry} onChange={e => setState({ ...state, registerCountry: e.target.value })} /> */}
          <input placeholder="Password" type="password" autoComplete="off" value={state.registerPassword} onChange={e => setState({ ...state, registerPassword: e.target.value })} />
          <input placeholder="Re-type Password" type="password" autoComplete="off" value={state.registerPassswordConfirm} onChange={e => setState({ ...state, registerPassswordConfirm: e.target.value })} />
          {state.isNotLevel1 ? (
            <>
              <label>
                <input type="checkbox" />
                I give my consent to be contacted by the Amadeus Payments Team.
              </label>
              <label className="mt-0">
                <input type="checkbox" checked={state.policyCheck} onChange={e => changeState('policyCheck')(e.target.checked)} />
                <a target="_blank" href="https://amadeus.com/en/policies/amadeus-policies" rel="noopener noreferrer">I confirm I have read the full privacy policy available <strong style={{ color: 'var(--color-main)' }}>here</strong></a>.
              </label>
            </>
          ) : (
            <p style={{ color: 'black', fontSize: '0.8em' }}>This username and password is required in order for us to track your progress throughout the course. We will record which modules have been viewed and the corresponding quiz results. On successful completion of the course, we will use your email address to send you personalized certificate. To read our full privacy statements, please <b><a href="https://amadeus.com/en/policies/amadeus-policies" target="_blank">click here</a></b>.</p>
          )}
          <input type="submit" value="Register now →" />
          <span className="close pointer" onClick={() => changeState('showRegisterModal')(false)}>X</span>
        </form>
      </Modal>
      <Modal open={state.showForgotPasswordModal} onClose={() => changeState('showForgotPasswordModal')(false)}>
        {state.hasResetCode ? (
          <form className="form forgot-password-form" onSubmit={onUpdatePassword}>
          <h4>Update password</h4>
          <p>Please check your mail box for reset code and use it to set a new password.</p>
          <input placeholder="Email" type="text" value={state.forgotEmail} onChange={e => setState({ ...state, forgotEmail: e.target.value })} />
          <input placeholder="New Password" type="password" autoComplete="off" value={state.newPassword} onChange={e => setState({ ...state, newPassword: e.target.value })} />
            <input placeholder="Reset Code" type="text" value={state.resetCode} onChange={e => setState({ ...state, resetCode: e.target.value })} />
          <p><a href="#" onClick={() => setState({ ...state, hasResetCode: false })}>← Back</a></p>
          <input type="submit" value="Update →" />
          <span className="close pointer" onClick={() => changeState('showForgotPasswordModal')(false)}>X</span>
        </form>
        ) : (
          <form className="form forgot-password-form" onSubmit={onForgotPassword}>
            <h4>Forgot password</h4>
            <input placeholder="Email" type="text" value={state.forgotEmail} onChange={e => setState({ ...state, forgotEmail: e.target.value })} />
            <p><a href="#" onClick={() => setState({ ...state, hasResetCode: true })}>Have a reset code?</a></p>
            <input type="submit" value="Send →" />
            <span className="close pointer" onClick={() => changeState('showForgotPasswordModal')(false)}>X</span>
          </form>
        )}
      </Modal>
      <Modal open={state.showSuccessModal} onClose={() => changeState('showSuccessModal')(false)}>
        <p>{state.showSuccessUpdatePasswordText ? 'Your password has been updated!': 'We have sent your forgotten password email.'}</p>
      </Modal>
    </BackgroundContainer>
  );
};

export default withRouter(Login);
