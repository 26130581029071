import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Store from 'store';

const Logout = (props) => {

  useEffect(() => {
    Store.User.logout();
  }, [])

  return (
    <Redirect to="/" />
  );
}

export default Logout;