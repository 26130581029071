export default {
  'introduction': 'This level of training suits those of you who have been in payments for less than 5 years, and are looking to expand your knowledge more broadly to get a deeper understanding of travel payments. There is a tailored course for Airlines, Travel Agencies and Hospitality providers. Below are details of the airline payments content. We will add the Travel Agency and Hospitality course content soon. If you are interested in one of these courses, register your interest by sending an email to travel.payments@amadeus.com and we will keep you informed.',
  'questions': [
    {
      question: 'What will I learn?',
      answer: [
        {
          title: 'Travel payments',
          subContent: [
           'Airline Industry requirements: FSCs Vs LCCs',
           'Travel sales channels: Distribution (GDS) vs eCommerce',
           'Front-ends and checkout processes on direct sales',
           'New distribution Channels: NDC',
           'PNR integration'
          ],
        },
        {
          title: 'Alternative Methods of Payments (AMOPs). “Life is not only about credit cards"',
          subContent: [
           'Main AMOPs in travel industry and detailed explanations',
          ],
        },
        {
          title: 'Regulators & Regulations. “Taking care of stakeholders’ interests”',
          subContent: [
           'Main organisms in payments industry: What is their role?',
           'Main and upcoming regulations in travel payments: Deep dive in PSD2/Travel- SCA',
          ],
        },
        {
          title: 'Security in Travel Payments: Authentication and fraud detection',
          subContent: [
           'Find out how PSD2 will impact eCommerce travel payments',
           'Impacts on Amadeus customers and business',
           'Understanding fraud in the travel industry',
          ],
        },
        {
          title: 'Travel Industry Competitive intelligence',
          subContent: [
           'Worldwide snapshot: Regions’ e-com spend and payments trends. Travel payments and best practices around the world',
          ],
        },
      ]
    },
    {
      question: 'What are the benefits of the training and why should I go?',
      answer: 'You can benefit from this training if you have been in payments for a while and learnt about the industry by other generic sources of information. If you have already had internal training or are simply doing the job (less than 5 years in travel payments) and need to get deeper insights into how travel payments works this training is for you. Take up this opportunity if you would like to take your travel payments knowledge to the next level'
    },
  ]
};
