import React from 'react';
import classnames from 'classnames';

import './Footer.scss';

interface Props {
  isWhiteFooter?: boolean
}

export default (props) => {
  return (
    <div className={classnames("footer", !props.isWhiteFooter && ('footer-blue'))}>
      <div className="container main-footer">
      	<div className="row">
      		<div className="col-12">
      			© Amadeus IT Group SA
            <a href="https://amadeus.com/en/policies/amadeus-policies" target="_blank">
              <span style={{ float: 'right' }}>
                Legal Notices & Privacy
              </span>
            </a>
      		</div>
      	</div>
      </div>
      {props.isWhiteFooter && (
        <div className="footer-line"></div>
      )}      
    </div>
  );
};
