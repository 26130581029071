export default {
  'introduction': 'This stage is designed for users who already have a great understanding of payments in the travel industry and would really like to deep dive into specialist knowledge of specific areas',
  'questions': [
    {
      question: 'What will I learn?',
      answer: [
        // 'Specific training on key travel segments',
        // 'Payments topics',
        // 'SCA, NDC payments (advanced), fraud management in travel payments, pay performance optimization, etc.'
        {
          title: 'Learn about the most up to date travel payments industry trends and needs and how you can adapt to them',
          subContentTick: true,
        },
        {
          title: 'This level of training will be highly specialised, with some of the topics possibly being:',
          subContentTick: true,
          subContent: [
           'Advanced security in Travel payments: SCA, Fraud Management, etc',
           'NDC Payments: advanced vision (conversion, fraud, data, etc)',
           'Payments Performance: improvement tips and strategies, lessons learned, best practices',
           'Payments Strategy: definition, key areas, metrics, etc',
          ],
        },
      ]
    },
    {
      question: 'What are the benefits of the training and why should I go?',
      answer: 'If you are looking for a specialised face to face training and already have a good understanding of the travel payments industry, this training is for you. Get training on specific areas of the travel payments industry now'
    },
    // {
    //   question: 'What is the cost?',
    //   answer: '€2000 per attendee'
    // },
  ]
};