import React from 'react';
import { Link } from 'react-router-dom';
import './LevelIntroduction.scss';

import boxImg1 from 'images/level-1.png';
import boxImg2 from 'images/level-2.png';
import boxImg3 from 'images/level-3.png';

export default (props) => {
  return (
    <div className="level-introduction">
      <div className="container">
      	<div className="row">
      		<div className="col-12 col-sm-4">
      			<div className="level-item">
              <h3 className="level-heading">Level 1</h3>
              <div className="level-box">
                <div className="level-top">
                  <img src={boxImg1} alt="" />
                  <div className="level-detail">
                    <h3>Online training</h3>
                    <h4>On-demand online training</h4>
                    <ul>
                      <li>Learn about payments fundamentals.</li>
                      <li>Discover top-line information about the payments industry. E.g: What is an issuer? What is an acquirer? What are the roles of PSPs, schemes, regulators, business models, etc.</li>
                      <li>Please be aware this training can only be completed on desktop due to its functionality.</li>
                    </ul>
                  </div>
                </div>
                <div className="level-bottom">
                  <a href="/level-1-training">Start your free training</a>
                </div>
              </div>
            </div>
      		</div>
          <div className="col-12 col-sm-4">
            <div className="level-item">
              <h3 className="level-heading">Level 2</h3>
              <div className="level-box">
                <div className="level-top">
                  <img src={boxImg2} alt="" />
                  <div className="level-detail">
                    <h3>Training</h3>
                    <h4>Group seminar training</h4>
                    <ul>
                      <li>Get specialised training close to in-region travel events.</li>
                      <li>Learn about travel sales and channels: Distribution (GDS) vs eCommerce, front-ends and checkout processes on direct sales.</li>
                      <li>Discover more about new distribution channels: NDC (basics) PNR integration, AMOPs etc.</li>
                    </ul>
                  </div>
                </div>
                <div className="level-bottom">
                  <a href="/level-2-overview">Find out more</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="level-item">
              <h3 className="level-heading">Level 3</h3>
              <div className="level-box">
                <div className="level-top">
                  <img src={boxImg3} alt="" />
                  <div className="level-detail">
                    <h3>Bespoke</h3>
                    <h4>Specialised face to face training</h4>
                    <ul>
                      <li>Get highly bespoke training at payment specific events e.g. Amadeus Future of Payments, IATA World Financial Symposium.</li>
                      <li>Be exposed to specific training on key travel payments topics.</li>
                      <li>Advanced training on SCA, NDC payments, fraud management in travel payments etc.</li>
                    </ul>
                  </div>
                </div>
                <div className="level-bottom">
                  <a href="/level-3-bespoke">Find out more</a>
                </div>
              </div>
            </div>
          </div>
      	</div>
      </div>
    </div>
  );
};
