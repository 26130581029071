import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { LEVEL_1 } from 'data';
import classnames from 'classnames';
import { BackgroundContainer, Container, Header, Modal } from 'components';
import Store from 'store';

import LevelOneSidebar from './LevelOneSidebar';
import LevelOneContentDetail from './LevelOneContentDetail';
import Instruction from './Instruction';

import './LevelOne.scss';

const LevelOne = (props) => {

  const [state, setState] = useState({
    completed: Store.User.getCompletedQuiz(),
    focus: '',
    sidebarSlide: false,
    isCompleted: false,
    showInstruction: true,
    certificateURL: '',
  });

  const checkAndMarkCompleteSideBar = () => {
    setState({ ...state, completed: Store.User.getCompletedQuiz() });
  };

  const getNextModuleInfo = () => {
    const titleArr = Object.keys(LEVEL_1);
    const index = titleArr.indexOf(state.focus);
    const nextIndex = index === titleArr.length - 1 ? 0 : index + 1;
    return titleArr[nextIndex];
  }

  const goToNextModule = () => {
    console.log('goToNextModule')
    const titleArr = Object.keys(LEVEL_1);
    const index = titleArr.indexOf(state.focus);
    const nextIndex = index === titleArr.length - 1 ? 0 : index + 1;
    console.log('index', index, 'nextIndex', nextIndex);
    setState({
      ...state,
      focus: titleArr[nextIndex],
    })
  };

  const handleClickSidebar = () => {
    setState({ ...state, sidebarSlide: !state.sidebarSlide });
  };

  const handleClickContentDetail = () => {
    if (state.sidebarSlide) {
      setState({ ...state, sidebarSlide: false });
    }
  }

  const getProgress = async () => {
    const [res, err] = await Store.User.getProgressQuiz();
    if (err) return;
    if (!res.success) return;
    if (res.data) {
      console.log("res.data", res.data);
      Store.User.saveQuizData(JSON.parse(res.data.data));
      setState({
        ...state,
        isCompleted: Store.User.checkAllQuizzesCompleted(),
        completed: Store.User.getCompletedQuiz(),
        certificateURL: res.data.certificate,
      })
    }
  }

  useEffect(() => {
    getProgress();
  }, []);

  if (!Store.User.isLoggedIn()) {
    return <Redirect to="/login1?redirect=/level-1-training" />
  }

  return (
    <div className="level-1-page">
      <header className="level-1-page__header">
        <Container>
          <Header
            hasIconHome
            rightColors={["white", "white"]}
          />
          <h1 className="level-1-page__header__title">Level 1: Online training</h1>
        </Container>
      </header>
      <main className="row level-1-content">
        <div className="col-3 col-md-3" />
        <section
          className={classnames(
            state.sidebarSlide ? "col-10 slide" : "col-3",
            state.sidebarSlide ? "col-md-6" : "col-md-3",
            "level-1-content__progress",
          )}
        >
          <LevelOneSidebar
            data={LEVEL_1}
            completed={state.completed}
            focus={state.focus}
            onChangeFocus={(value) => setState({ ...state, focus: value, showInstruction: false })}
            onToggleIconClick={handleClickSidebar}
            isToggled={state.sidebarSlide}
            onClickInstruction={() => setState({ ...state, showInstruction: true, focus: '' })}
          />
        </section>
        <section className={classnames("col-9 col-md-9", "level-1-content__detail")} onClick={handleClickContentDetail}>
          {state.showInstruction ? (
            <Instruction
              isCompleted={state.isCompleted}
              certificateUrl={state.certificateURL}
              onStart={() => setState({ ...state, focus: 'Introduction', showInstruction: false })}
            />
          ) : (
            <LevelOneContentDetail
              data={LEVEL_1}
              focus={state.focus}
              key={state.focus}
              onOneQuizComplete={checkAndMarkCompleteSideBar}
              onNext={goToNextModule}
              getNextModuleInfo={getNextModuleInfo}
              isCompleted={state.isCompleted}
            />
          )}
          {state.sidebarSlide && <div className="absolute-fill-overlay" />}
        </section>
        <a className="footer-privacy-links" href="https://amadeus.com/en/policies/amadeus-policies" target="_blank">Legal Notices & Privacy</a>
      </main>
    </div>
    
  );
};

export default withRouter(LevelOne);
