import React, { useState } from 'react';
import classnames from 'classnames';

import './LevelPlaces.scss';
import overlayImg from 'images/TBC-01.png';

interface Props {
  noInfoYet?: boolean;
  places?: Array<any>;
  onChangeEvent?(event: any): void;
} 

export default (props : Props) => {
  const [state, setState] = useState({
    activeEventIndex: undefined,
  });

  const setActiveEvent = (i) => {
    setState({ ...state, activeEventIndex: i });
    !!props.onChangeEvent && !!props.places && props.onChangeEvent(props.places[i]);
  }

  if (props.noInfoYet) {
    return (
      <div className="places-section">
        <div className="row">
          <div className="col-12">
            <h2 className="heading">When will be it?</h2>
          </div>
          <div className="col-12">
            <p className="tbc-text">Currently the dates for this level are TBC</p>
            <p className="tbc-text">More details will follow shortly</p>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="places-section">
    	<div className="row">
    		<div className="col-12">
    			<h2 className="heading">When will be it?</h2>
    		</div>
    	</div>
      <div className="row places-items">
        {!!props.places && props.places.map((val, i) => (
          <div className="col-6 col-md-3 mb-3 mb-md-0 pointer" key={"event-" + i} onClick={() => setActiveEvent(i)}>
            <div className={classnames("places-item", state.activeEventIndex === i ? 'active' : 'inactive')}>
              <div className="item-top">
                <h4>{val.eventLocation}</h4>
                <p>{val.eventTime}</p>
              </div>
              <div className="item-content">
                <img src={val.image} alt="" />
                <div className="text">
                  <p>{val.eventDescription}</p>
                </div>
                <div className="overlay">
                  <img src={overlayImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
