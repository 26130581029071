import React, { useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Main, Header, Banner, LevelOverview, LevelPlaces, LevelRegisterNew, Footer } from 'components';
import { LEVEL_3 } from 'data';
import Store from 'store';

import './LevelThreeBespoke.scss';

import bgImg from 'images/bg-level-3.png';

const LevelThreeBespoke = (props) => {
  const [state, setState] = useState({
    event: undefined,
  });

  // const checkCompleteQuiz = async () => {
  //   const [res, err] = await Store.User.checkCompletedQuiz();
  //   if (err) return alert(String(err));
  //   if (!res.data.isCompleted) {
  //     alert('You need to complete level 1 first!');
  //     props.history.push('/level-1-training');
  //   }
  // }

  // new update, remove check complete quiz
  // useEffect(() => {
  //   if (Store.User.isLoggedIn()) {
  //     checkCompleteQuiz();
  //   }
  // }, []);

  // if (!Store.User.isLoggedIn()) {
  //   return <Redirect to="/login?redirect=/level-3-bespoke" />;
  // }

  const onRegister = async ({ fullName, companyName, email, phone, industry, country, question }) => {
    const [res, err] = await Store.User.submitLevel23({
      fullName,
      companyName,
      email,
      phone,
      industry,
      country,
      eventDescription: !!state.event ? state.event.eventDescription : '',
      eventLocation: !!state.event ? state.event.eventLocation : '',
      eventTime: !!state.event ? state.event.eventTime : '',
      level: 3,
      question,
    });
    if (err) return alert(String(err));
    if (res.error) return alert(String(res.message));
    return alert('You have registered successfully!');
  }

  return (
    <div className="level-3-page">
      <Main>
        <div className="banner-header">
          <div className="container">
            <Header
              // hasLoginButton
              hasIconHome
              rightColors={["white", "white", "white"]}
              loginLink="/login?redirect=/level-3-bespoke"
            />
          </div>
        </div>
        <Banner
          img={bgImg}
          title="Level 3: Bespoke"
        />
        <div className="container">
          <LevelOverview
            data={LEVEL_3}
          />
          <LevelPlaces noInfoYet />
          <LevelRegisterNew level={3} onRegister={onRegister} />
        </div>
      </Main>
      <Footer />
    </div>
  );
};

export default withRouter(LevelThreeBespoke);
