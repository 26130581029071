import React, { useState } from 'react';
import { Container, Video } from 'components';
import classnames from 'classnames';
import Store from 'store';
import Quiz from './Quiz';
import './LevelOneContentDetail.scss';


interface Props {
  data: object,
  focus: string,
  onOneQuizComplete?(): any,
  onNext?(): any,
  getNextModuleInfo(): any,
  isCompleted: boolean,
}

const TAB = {
  VIDEO_DESCRIPTION: 'VIDEO_DESCRIPTION',
  RESOURCES: 'RESOURCES',
  QUIZ: 'QUIZ',
}

const LevelOneContentDetail = (props: Props) => {

  const content = props.data[props.focus];
  const [state, setState] = useState({
    tabDisplay: TAB.VIDEO_DESCRIPTION,
    lockQuiz: true,
  });

  const onClickTab = (tab) => () => {
    if (tab === TAB.QUIZ && state.lockQuiz) {
      alert('You need to watch the video first before doing the quiz');
      return;
    }
    setState({ ...state, tabDisplay: tab })
  }

  const currentQuizData = content.quiz;

  return (
    <Container>
      <div className="lv1-content">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <h1 className="text-center title">{content.title}</h1>
            {state.tabDisplay === TAB.QUIZ ? (
              <div>
                <Quiz content={content} onComplete={props.onOneQuizComplete} getNextModuleInfo={props.getNextModuleInfo} onNext={props.onNext} isCompletedAll={props.isCompleted} />
              </div>
            ) : (
              <div>
                <Video
                  src={content.video}
                  srcSub={content.videoSub}
                  onPlay={() => {
                    setState({ ...state, lockQuiz: false })
                    if (content.quiz.length === 0) {
                      Store.User.checkResultQuiz(content.title);
                      !!props.onOneQuizComplete && props.onOneQuizComplete();
                    }
                  }}
                />
              </div>
            )}

            <div className="tab-header m-t-40">
              <div className={classnames("tab-header__video-description", state.tabDisplay === TAB.VIDEO_DESCRIPTION ? 'active' : '')} onClick={onClickTab(TAB.VIDEO_DESCRIPTION)}>
                About this video
              </div>
              <div className={classnames("tab-header__resources", state.tabDisplay === TAB.RESOURCES ? 'active' : '')} onClick={onClickTab(TAB.RESOURCES)}>
                Resources
              </div>
              {currentQuizData.length > 0 &&
              <div className={classnames("tab-header__quiz", state.tabDisplay === TAB.QUIZ ? 'active' : '')} onClick={onClickTab(TAB.QUIZ)}>
                Quiz
              </div>}
            </div>

            <div className="tab-content m-t-20">
              {state.tabDisplay === TAB.VIDEO_DESCRIPTION && (
                <div className="tab-content__video-description">
                  {content.aboutThisVideo}
                </div>
              )}
              {state.tabDisplay === TAB.RESOURCES && (
                <div className="tab-content__video-description">
                  {content.resources.map((val, i) => (
                    <p key={"ri-"+i}><a href={val.value} target="_blank" rel="noopener noreferrer">{val.label}</a></p>
                  ))}
                </div>
              )}
              {state.tabDisplay === TAB.QUIZ && (
                <div className="tab-content__quiz">
                  <h4>Rules of the quiz</h4>
                  <ul>
                    <li>Watch the video, download the Powerpoint resources and complete the quiz to progress.</li>
                    <li>You can complete the sections in any order you like</li>
                    <li>You must at least start the video to be able to answer the quiz</li>
                    <li>You cannot have more than one wrong answer to pass each quiz</li>
                    <li>If you have more than one wrong answer, you will need to retry the quiz</li>
                    <li>At the end, when you've passed each quiz, you've pased the training and a personalized certificate will be emailed to you.</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LevelOneContentDetail;

