import React from 'react';
import classnames from 'classnames';
import './BackgroundContainer.scss';

import overlayImg from 'images/grey-gradient-overlay.png';

interface Props {
  img: string,
  withGreyGradientOverlay?: boolean,
  children: any,
  className?: string,
}

export default (props: Props) => {
  return (
    <div className={classnames("background-container", props.className)} style={{ backgroundImage: `url(${props.img})` }}>
      {props.withGreyGradientOverlay ? (
        <div className="grey-gradient-overlay-container" style={{ backgroundImage: `url(${overlayImg})` }}>
          {props.children}
        </div>
      ) : props.children}
    </div>
  )
};
