import React, { useState } from 'react';
import classnames from 'classnames';
import './Survey.scss';

import Store from 'store';

const Survey = ({ onSubmit }) => {
  const [q1, setQ1] = useState('');
  const [q2, setQ2] = useState('');
  const [q3, setQ3] = useState('');

  const submit = async () => {
    if (!q1 || !q2) return alert('Please complete the survey');
    Store.User.submitSurveyToServer({ q1, q2, q3 });
    onSubmit({ q1, q2, q3 });
  }

  const renderCheckboxs = (value, setValue) => {
    return (
      <div className="row checkbox-container__wrapper">
        {[
          'Completely agree',
          'Somewhat agree',
          'Neutral',
          'Somewhat disagree',
          'Completely disagree'
        ].map((val, i) => (
          <div className="col d-flex align-items-center checkbox-container" key={"checkbox-"+i} onClick={() => setValue(val)} >
            <div className={classnames("checkbox", val === value ? 'selected' : '')} />
            <span>{val}</span>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="quiz-message-modal big">
      <h1 className="mb-0">Congratulations</h1>
      <h3 className="text-center mb-3">You have completed Level 1</h3>
      <h3 className="text-center">Please fill in these 3 simple questions below to receive your certificate and LinkedIn badge</h3>
      <div className="survey-container">
        <p className="text-center">Did the training course meet your expectations</p>
        {renderCheckboxs(q1, setQ1)}
        <p className="text-center mt-2">Would you recommend the course to your contacts?</p>
        {renderCheckboxs(q2, setQ2)}
        <p className="text-center mt-4">Please share your comments, recommendations or suggestions for the course</p>
        <textarea value={q3} onChange={e => setQ3(e.target.value)} />
      </div>

      <button className="white-btn" onClick={submit}>Get your certificate and LinkedIn badge</button>
    </div>
  );
};

export default Survey;