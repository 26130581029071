// if you wish to edit the data, please edit here as well admin-assets/page_quiz_report.js

export default {
  'Introduction': {
    title: 'Introduction',
    video: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-Introduction-Module.mp4',
    videoSub: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-Introduction-Module-SUBS.mp4',
    aboutThisVideo: 'This video is a summary of the training program and what it will offer you as a package. Watch this video to learn about the three levels offered and how to complete this training. Good luck! ',
    resources: [
      { type: 'link', label: 'Introduction Slides.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Introduction+Support+Slides.pdf' },
      { type: 'link', label: 'Transcript.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Transcript/Amadeus+Payments+Academy+Video+Transcripts_introduction.pdf' },
    ],
    quiz: [],
  },
  'Payment Fundamentals and Ecosystem': {
    title: 'Payment Fundamentals and Ecosystem',
    video: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-PaymentFundamentalsEcosystems-Module.mp4',
    videoSub: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-PaymentFundamentalsEcosystems-Module-SUBS.mp4',
    aboutThisVideo: 'This video will teach you about the payments ecosystem and fundamentals. Within this, learn who the main actors in the payments ecosystem are and how these players produce payment methods to allow customers, card holders or people, to make payments. Also learn about the context of Travel Payments by learning how payments have evolved a lot throughout the years and how the way we pay has also changed. Watch this video and complete the quiz to progress!',
    resources: [
      { type: 'link', label: 'Payment Fundamentals and Ecosystem Support Slides.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Payment+Fundamentals+and+Ecosystem+Support+Slides.pdf' },
      { type: 'link', label: 'Transcript.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Transcript/Amadeus+Payments+Academy+Video+Transcripts_fundamentalsecosystem.pdf' },
    ],
    quiz: [
      {
        number: '1',
        question: 'True or false? By 2020, 20% of all pay transactions in Europe will be done with Alternative Methods of Payments.',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'a',
      },
      {
        number: '2',
        question: 'Which of these is not a player in the payments ecosystem?',
        answers: [
          { value: 'a', label: 'Acquirer' },
          { value: 'b', label: 'Card Manufacturer' },
          { value: 'c', label: 'Local Government' },
          { value: 'd', label: 'Card Scheme' },
        ],
        correct: 'c',
      },
      {
        number: '3',
        question: 'In a payments scope, banks can’t cover certain services (like processing) but sometimes they consider it of interest to resell these services. Who do banks entrust with these services?',
        answers: [
          { value: 'a', label: 'Other banks' },
          { value: 'b', label: 'PSPs' },
          { value: 'c', label: 'Schemes' },
          { value: 'd', label: 'Regulators' },
        ],
        correct: 'b',
      },
      {
        number: '4',
        question: 'True or false? In the 4-party transaction model scheme, a Bank or a Financial Institution can be an issuer and an acquirer at the same time.',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'a',
      },
      {
        number: '5',
        question: 'With whom does the merchant need to sign a contract to accept AMEX cards?',
        answers: [
          { value: 'a', label: 'With his bank (i.e. the merchant’s bank)' },
          { value: 'b', label: 'With the regulator' },
          { value: 'c', label: 'With AMEX (the scheme)' },
          { value: 'd', label: 'With the bank of the cardholder (customer’s bank)' },
        ],
        correct: 'c',
      },
    ],
  },
  'Payment processes': {
    title: 'Payment processes',
    video: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-PaymentProcesses-Module.mp4',
    videoSub: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-PaymentProcesses-Module-SUBS.mp4',
    aboutThisVideo: 'This video will teach you about the payments processes. Learn about the main payment processes within the business: authorization, clearing and settlement and chargebacks reconciliation and how they affect the payments industry. Watch the video and complete the quiz to progress!',
    nextStepText: 'Learn about the main payment processes within the business: authorization, clearing and settlement and chargebacks reconciliation and how they affect the payments industry.',
    resources: [
      { type: 'link', label: 'Payment Processes Support Slides.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Payments+Processes+Support+Slides.pdf' },
      { type: 'link', label: 'Transcript.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Transcript/620776+-+Payments+Academy+Video+Transcripts_v2.pdf' },
    ],
    quiz: [
      {
        number: '1',
        question: 'True or false? In an authorization process, an acquirer communicates with the card scheme to pass transaction details.',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'a',
      },
      {
        number: '2',
        question: 'What is the main reason for chargebacks?',
        answers: [
          { value: 'a', label: 'Criminal fraud' },
          { value: 'b', label: 'Friendly fraud' },
          { value: 'c', label: 'Merchant error' },
          { value: 'd', label: 'Technical issues' },
        ],
        correct: 'b',
      },
      {
        number: '3',
        question: 'In the clearing and settlement process, communication between a merchant and acquirer can be done',
        answers: [
          { value: 'a', label: 'Online - if eCommerce' },
          { value: 'b', label: 'Offline/polling - if physical terminal (face-to-face transactions)' },
          { value: 'c', label: 'Both of the above' },
          { value: 'd', label: 'None of the above' },
        ],
        correct: 'c',
      },
      {
        number: '4',
        question: 'Who provides the final approval (authorization) of a payment transaction?',
        answers: [
          { value: 'a', label: 'The card scheme' },
          { value: 'b', label: 'The issuer' },
          { value: 'c', label: 'The acquirer' },
          { value: 'd', label: 'The merchant' },
        ],
        correct: 'b',
      },
      {
        number: '5',
        question: '5.	Which of these measures is not valid to combat chargebacks?',
        answers: [
          { value: 'a', label: 'Track and properly manage chargeback disputes' },
          { value: 'b', label: 'Add manual processes for repetitive chargeback documentation' },
          { value: 'c', label: 'Use EMV, 3D-Secure and AVS to reduce “unauthorized transaction” chargebacks' },
          { value: 'd', label: 'Try to prevent chargebacks from occurring with a fraud management solution, high-quality products, superior customer service, and clear return/refund policies' },
        ],
        correct: 'b',
      },
    ]
  },
  'Acquirers': {
    title: 'Acquirers',
    video: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-Acquirers-Module.mp4',
    videoSub: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-Acquirers-Module-SUBS.mp4',
    aboutThisVideo: 'This video will teach you all about the acquirers in travel payments. Within this video, learn about the‘actors’ or ‘players of payments operations’ and how they convert electronic money into real money. Watch the video and complete the quiz to progress!',
    nextStepText: 'Learn about the ‘actors’ or ‘players of payments operations’ and how they convert electronic money into real money.',
    resources: [
      { type: 'link', label: 'Acquirers Support Slides.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Acquirers+Support+Slides.pdf' },
      { type: 'link', label: 'Transcript.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Transcript/Amadeus+Payments+Academy+Video+Transcripts_acquirers.pdf' },
    ],
    quiz: [
      {
        number: '1',
        question: 'When providing access to the payments network, acquirers.',
        answers: [
          { value: 'a', label: 'Provide access certifications' },
          { value: 'b', label: 'Validate the origin of the transaction' },
          { value: 'c', label: 'Take PCI-DSS liability' },
          { value: 'd', label: 'All of the above' },
        ],
        correct: 'd',
      },
      {
        number: '2',
        question: '2.	True or false? Knowing the merchant (performing a risk analysis based on the nature of goods and services sold) is NOT a success factor for acquirers',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'b',
      },
      {
        number: '3',
        question: 'Which of these roles is not in the scope of an acquirer?',
        answers: [
          { value: 'a', label: 'Provide cardholders with credit cards' },
          { value: 'b', label: 'Convert electronic transactions into money' },
          { value: 'c', label: 'Be the entry point for the acceptance scheme network' },
          { value: 'd', label: 'Take legal liability against schemes and regulators' },
        ],
        correct: 'a',
      },
      {
        number: '4',
        question: 'Amongst acquirer risks, what does KYC stand for?',
        answers: [
          { value: 'a', label: 'Key Year Customers' },
          { value: 'b', label: 'Know Your Customer' },
          { value: 'c', label: 'Know Young Customers' },
          { value: 'd', label: 'Key Yearly Communications' },
        ],
        correct: 'b',
      },
      {
        number: '5',
        question: 'Acquires consider the Airline and Travel industry generally risky because',
        answers: [
          { value: 'a', label: 'Tickets are very cheap' },
          { value: 'b', label: 'Airplanes are highly pollutive' },
          { value: 'c', label: 'Both of the above' },
          { value: 'd', label: 'Their fixed costs, with low margin, among other reasons' },
        ],
        correct: 'd',
      },
    ]
  },
  'Issuers': {
    title: 'Issuers',
    video: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-Issuers-Module.mp4',
    videoSub: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-Issuers-Module-SUBS.mp4',
    aboutThisVideo: 'This video will teach you all about the issuers in travel payments. Learn about what issuers are, the services they offer to the cardholders in order to allow them to operate in payment transactions and the way they work. Watch the video and complete the quiz to progress!',
    nextStepText: 'Learn about what issuers are, the services they offer to the cardholders in order to allow them to operate in payment transactions and the way they work.',
    resources: [
      { type: 'link', label: 'Issuers Support Slides.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Issuers+Support+Slides.pdf' },
      { type: 'link', label: 'Transcript.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Transcript/Amadeus+Payments+Academy+Video+Transcripts_issuers.pdf' },
    ],
    quiz: [
      {
        number: '1',
        question: 'Issuers do product positioning based on',
        answers: [
          { value: 'a', label: 'Customer type' },
          { value: 'b', label: 'Payments functionality' },
          { value: 'c', label: 'Card attributes' },
          { value: 'd', label: 'All the above' },
        ],
        correct: 'd',
      },
      {
        number: '2',
        question: 'Issuer banks assign PANs to cardholders, but what does PAN stand for?',
        answers: [
          { value: 'a', label: 'Primary Account Number' },
          { value: 'b', label: 'Primary Account Name' },
          { value: 'c', label: 'Priority Access Number' },
          { value: 'd', label: 'Preferred Access Number' },
        ],
        correct: 'a',
      },
      {
        number: '3',
        question: 'True or false? Friendly fraud (where the cardholder has enjoyed the service but tries to reverse payment) is an internal risk for Issuers',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'a',
      },
      {
        number: '4',
        question: 'True or false? ATM cards are limited to ATM transactions such as deposits or cash withdrawals',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'a',
      },
      {
        number: '5',
        question: 'What are the two main categories of card products based on “customer type”?',
        answers: [
          { value: 'a', label: 'Cobrand and Corporate' },
          { value: 'b', label: 'Consumer and Commercial' },
          { value: 'c', label: 'Private and Public' },
          { value: 'd', label: 'Basic and Complex' },
        ],
        correct: 'b',
      },
    ]
  },
  'Payment Service Providers (PSPs)': {
    title: 'Payment Service Providers (PSPs)',
    video: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-PaymentServiceProviders-Module.mp4',
    videoSub: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-PaymentServiceProviders-Module-SUBS.mp4',
    aboutThisVideo: 'This video will teach you all about the role and responsibility of one of the most versatile players in the ecosystem, the PSP’s. Watch the video and complete the quiz to progress!',
    nextStepText: 'This video will teach you all about the role and responsibility of one of the most versatile players in the ecosystem, PSPs.',
    resources: [
      { type: 'link', label: 'PSP Support Slides.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/PSP\'s+Support+Slides.pdf' },
      { type: 'link', label: 'Transcript.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Transcript/Amadeus+Payments+Academy+Video+Transcripts_serviceproviders.pdf' },
    ],
    quiz: [
      {
        number: '1',
        question: 'What does PSP stand for?',
        answers: [
          { value: 'a', label: 'Personal Service Payment' },
          { value: 'b', label: 'Payment Secure Provider' },
          { value: 'c', label: 'Payment Subscription Provider' },
          { value: 'd', label: 'Payment Service Provider' },
        ],
        correct: 'd',
      },
      {
        number: '2',
        question: 'Which of the following is not a role of a PSP?',
        answers: [
          { value: 'a', label: 'Payments Gateway' },
          { value: 'b', label: 'Payments Processor' },
          { value: 'c', label: 'Payments Provider' },
          { value: 'd', label: 'Issue Payments' },
        ],
        correct: 'd',
      },
      {
        number: '3',
        question: 'Finish this sentence: A PSP, acting as a payment processor…',
        answers: [
          { value: 'a', label: 'Routes transactions' },
          { value: 'b', label: 'Processes transactions' },
          { value: 'c', label: 'Is able to provide multiple routing possibilities' },
          { value: 'd', label: 'Enables payment services' },
        ],
        correct: 'b',
      },
      {
        number: '4',
        question: 'Finish this sentence: A PSP, acting as a payment system…',
        answers: [
          { value: 'a', label: 'Routes transactions' },
          { value: 'b', label: 'Routes and processes transactions' },
          { value: 'c', label: 'Is able to provide multiple routing possibilities' },
          { value: 'd', label: 'Enables payment services' },
        ],
        correct: 'c',
      },
      {
        number: '5',
        question: 'When selecting a PSP, merchants should NOT take into account',
        answers: [
          { value: 'a', label: 'Compatibility' },
          { value: 'b', label: 'Country and currency support' },
          { value: 'c', label: 'Payment methods' },
          { value: 'd', label: 'Corporate image' },
        ],
        correct: 'd',
      },
    ]
  },
  'Schemes, regulators and regulations': {
    title: 'Schemes, regulators and regulations',
    video: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-SchemesRegulatorsandRegulations-Module.mp4',
    videoSub: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-SchemesRegulatorsandRegulations-Module-SUBS.mp4',
    aboutThisVideo: 'This video will teach you about the specific players in the payments process, such as the schemes and regulators and their responsibilities, as well as the role they play in the payments ecosystem. Watch the video and complete the quiz to progress!',
    nextStepText: 'This video will teach you about the specific players in the payments process, such as the schemes and regulators and their responsibilities, as well as the role they play.',
    resources: [
      { type: 'link', label: 'Schemes_Regulators and Regulations Slides.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Schemes%2C+Regulators+and+Regulations+Support+Slides.pdf' },
      { type: 'link', label: 'Transcript.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Transcript/Amadeus+Payments+Academy+Video+Transcripts_schemesregulations.pdf' },
    ],
    quiz: [
      {
        number: '1',
        question: 'From the following options, which one is not a card scheme?',
        answers: [
          { value: 'a', label: 'Mastercard' },
          { value: 'b', label: 'JCB' },
          { value: 'c', label: 'Union Pay' },
          { value: 'd', label: 'Western Union' },
        ],
        correct: 'd',
      },
      {
        number: '2',
        question: 'What does a network scheme NOT do?',
        answers: [
          { value: 'a', label: 'Connect issuers and acquirers' },
          { value: 'b', label: 'Route transactions for authorization and clearing' },
          { value: 'c', label: 'Bills card holders' },
          { value: 'd', label: 'Set rules and standards Union' },
        ],
        correct: 'c',
      },
      {
        number: '3',
        question: 'What kind of information does a card BIN number provide?',
        answers: [
          { value: 'a', label: 'The scheme brand and the card number' },
          { value: 'b', label: 'The cardholder ID and scheme brand' },
          { value: 'c', label: 'The scheme brand and issuer bank' },
          { value: 'd', label: 'The cardholder ID' },
        ],
        correct: 'c',
      },
      {
        number: '4',
        question: 'True or false? The main objective of a card scheme is to create an acceptance network and grow its branded cards by giving BIN licenses to “member” banks',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'a',
      },
      {
        number: '5',
        question: 'True or false? Schemes act as “arbitrators” between issuers and acquirers in chargebacks and disputes.',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'a',
      },
      {
        number: '6',
        question: 'Which of the following is NOT a regulator’s role in a payments environment?',
        answers: [
          { value: 'a', label: 'Ensure payments are operated safely and efficiently' },
          { value: 'b', label: 'Collect service fees' },
          { value: 'c', label: 'Ensure that payment-related costs are non-abusive' },
          { value: 'd', label: 'Enhance competition and harmonize market' },
        ],
        correct: 'b',
      },
      {
        number: '7',
        question: 'Which of the following are NOT specific payments-related regulations?',
        answers: [
          { value: 'a', label: 'EMV liability shift EEUU' },
          { value: 'b', label: 'eCommerce transactions rules in India' },
          { value: 'c', label: 'Payment Service Directive 2 (PSD2) in Europe' },
          { value: 'd', label: 'GDPR – General Data Protection Regulation' },
        ],
        correct: 'd',
      },
      {
        number: '8',
        question: 'What does PSD2 stand for?',
        answers: [
          { value: 'a', label: 'Payment Service Directive 2' },
          { value: 'b', label: 'Personal Service Directory 2' },
          { value: 'c', label: 'Payment Secure Directive 2' },
          { value: 'd', label: 'Payment Subscription Directory 2' },
        ],
        correct: 'a',
      },
      {
        number: '9',
        question: 'True or false? There is one unique Global Payments Regulator that drives all payment rules worldwide',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'b',
      },
      {
        number: '10',
        question: 'What is the specific organism/regulator of the Airlines Industry that also has an impact on payment regulation and execution?',
        answers: [
          { value: 'a', label: 'European Payment Council' },
          { value: 'b', label: 'The Federal Bureau of America' },
          { value: 'c', label: 'IATA' },
          { value: 'd', label: 'Central Bank of Europe' },
        ],
        correct: 'c',
      },
    ]
  },
  'Alternative Methods of Payments (AMOPs)': {
    title: 'Alternative Methods of Payments (AMOPs)',
    video: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-AlternativeMethodsofPayments-Module.mp4',
    videoSub: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-AlternativeMethodsofPayments-Module-SUBS.mp4',
    aboutThisVideo: 'This video will teach you about the Alternative Methods of Payments, also known as AMOPs. Learn how AMOPs is the answer to the industry for new customer requirements. Watch the video and complete the quiz to progress!',
    nextStepText: 'This video will teach you about Alternative Methods of Payments, also known as AMOPs. Learn how AMOPs are the answer to the industry for new customer requirements.',
    resources: [
      { type: 'link', label: 'AMOPs Support Slides.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/AMOPs+Support+Slides.pdf' },
      { type: 'link', label: 'Transcript.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Transcript/Amadeus+Payments+Academy+Video+Transcripts_alternative+methods.pdf' },
    ],
    quiz: [
      {
        number: '1',
        question: 'Which of the following methods of payment is not considered an AMOP?',
        answers: [
          { value: 'a', label: 'Cheques' },
          { value: 'b', label: 'Cash on delivery' },
          { value: 'c', label: 'Bank Transfers' },
          { value: 'd', label: 'E-Wallets' },
        ],
        correct: 'a',
      },
      {
        number: '2',
        question: 'Which of these is not an AMOP category?',
        answers: [
          { value: 'a', label: 'Synchronous' },
          { value: 'b', label: 'Near-real time' },
          { value: 'c', label: 'Few hours' },
          { value: 'd', label: 'Asynchronous' },
        ],
        correct: 'c',
      },
      {
        number: '3',
        question: 'True or false? AMOPs requested by buyers in the Retail Industry are the same as AMOPs requested by travelers in Travel Industry',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'b',
      },
      {
        number: '4',
        question: 'Which of these features is not AMOPs related?',
        answers: [
          { value: 'a', label: 'More secure' },
          { value: 'b', label: 'Costly' },
          { value: 'c', label: 'Global reach' },
          { value: 'd', label: 'Universal' },
        ],
        correct: 'd',
      },
      {
        number: '5',
        question: 'In addition to sales conversion increase, AMOPs sometimes causes a cannibalization of traditional payments',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'a',
      },
    ]
  },
  'Business Models': {
    title: 'Business Models',
    video: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-BusinessModels-Module.mp4',
    videoSub: 'https://payment-academy.s3-eu-west-1.amazonaws.com/new-videos/620776-Payments-Academy-BusinessModels-Module-SUBS.mp4',
    aboutThisVideo: 'This video will teach you about the business model behind the payment transactions. Learn more about the interchange ++ and the fees that are behind each of those transactions that we process every day. Watch the video and complete the quiz to progress!',
    nextStepText: 'This video will teach you about the business model behind the payment transactions. Learn more about the interchange ++ and the fees that are behind each of those transactions that we process every day.',
    resources: [
      { type: 'link', label: 'Business Models Support Slides.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Business+Model+Support+Slides.pdf' },
      { type: 'link', label: 'Transcript.pdf', value: 'https://payment-academy.s3-eu-west-1.amazonaws.com/training-resouces/Transcript/Amadeus+Payments+Academy+Video+Transcripts_business.pdf' },
    ],
    quiz: [
      {
        number: '1',
        question: 'Merchant service fee is NOT composed of',
        answers: [
          { value: 'a', label: 'Interchange fee' },
          { value: 'b', label: 'Government/state task' },
          { value: 'c', label: 'Acquirer mark up' },
          { value: 'd', label: 'Scheme fee' },
        ],
        correct: 'b',
      },
      {
        number: '2',
        question: 'Scheme fees are paid by both issuer and acquirer  to the merchant',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'b',
      },
      {
        number: '3',
        question: 'Interchange fee is the fix price that acquirers pay to issuers in any payment transaction',
        answers: [
          { value: 'a', label: 'True' },
          { value: 'b', label: 'False' },
        ],
        correct: 'a',
      },
      {
        number: '4',
        question: 'Who is the player that takes more revenue in a 4 squares model - card payment transaction?',
        answers: [
          { value: 'a', label: 'Acquirer' },
          { value: 'b', label: 'Issuer' },
          { value: 'c', label: 'Scheme' },
          { value: 'd', label: 'Regulator' },
        ],
        correct: 'b',
      },
      {
        number: '5',
        question: 'Who is the player that takes more revenue in a 3 squares model - Card Payment Transaction?',
        answers: [
          { value: 'a', label: 'Acquirer' },
          { value: 'b', label: 'Issuer' },
          { value: 'c', label: 'Scheme' },
          { value: 'd', label: 'Regulator' },
        ],
        correct: 'c',
      },
    ]
  },
};