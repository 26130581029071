import React, { useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Main, Header, Banner, LevelOverview, LevelPlaces, LevelRegister, Footer, LevelRegisterNew } from 'components';
import { OVERVIEW_LEVEL_2 } from 'data';

import './LevelTwoOverview.scss';
import img1 from 'images/place-1.png';
import img2 from 'images/place-2.png';
import img3 from 'images/place-3.png';
import img4 from 'images/place-4.png';
import bgImg from 'images/bg-level-2.png';
import Store from 'store';

const LevelTwoOverview = (props) => {
  const [state, setState] = useState({
    event: undefined,
  });

  // const checkCompleteQuiz = async () => {
  //   const [res, err] = await Store.User.checkCompletedQuiz();
  //   if (err) return alert(String(err));
  //   if (!res.data.isCompleted) {
  //     alert('You need to complete level 1 first!');
  //     props.history.push('/level-1-training');
  //   }
  // }

  const onRegister = async ({ fullName, companyName, email, phone, industry, country, question }) => {
    if (!state.event) return alert('Please choose an event');
    const [res, err] = await Store.User.submitLevel23({
      fullName,
      companyName,
      email,
      phone,
      industry,
      country,
      eventDescription: state.event.eventDescription,
      eventLocation: state.event.eventLocation,
      eventTime: state.event.eventTime,
      level: 2,
      question,
    });
    if (err) return alert(String(err));
    if (res.error) return alert(String(res.message));
    return alert('You have registered successfully!');
  }

  // new update, remove check complete quiz
  // useEffect(() => {
  //   if (Store.User.isLoggedIn()) {
  //     checkCompleteQuiz();
  //   }
  // }, []);

  // if (!Store.User.isLoggedIn()) {
  //   return <Redirect to="/login?redirect=/level-2-overview" />;
  // }

  return (
    <div className="level-2-page">
      <Main>
        <div className="banner-header">
          <div className="container">
            <Header
              // hasLoginButton
              hasIconHome
              rightColors={["blue", "blue", "blue"]}
              loginLink="/login?redirect=/level-2-overview"
            />
          </div>
        </div>
        <Banner
          img={bgImg}
          title="Level 2: Training"
        />
        <div className="container">
          <LevelOverview
            data={OVERVIEW_LEVEL_2}
          />
          <LevelPlaces
            noInfoYet
          />
          <LevelRegisterNew level={2} onRegister={onRegister} />
        </div>
      </Main>
      <Footer />
    </div>
  );
};

export default withRouter(LevelTwoOverview);
