import axios from 'axios';
import erria from 'erria/decorator';
import { HOST } from './host';
const qs = require('qs');

class RequestHelper {

  _token

  setToken = (token) => {
    this._token = token;
  }

  getToken = () => {
    return this._token;
  }

  makeHeader = method => {
    let token = this.getToken();
    let headers = {};
    if(token) headers["Authorization"] = "Bearer " + token;
    if(method === "POST") headers["Content-Type"] = "application/json";
    return headers;
  }

  querify = (url, queryObject) => {
    let newUrl = url;
    if(!queryObject) return newUrl;
    newUrl += "?" + qs.stringify(queryObject);
    return newUrl;
  }

  @erria
  async get(URL, queryObject) : Promise<any> {
    const urlWithQuery = this.querify(HOST + URL, queryObject);
    const res = await axios.request({
      url: urlWithQuery,
      method: 'get',
      headers: this.makeHeader("GET"),
    });
    return {
      status: res.status,
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

  @erria
  async post(URL, bodyObject) : Promise<any> {
    const res = await axios.request({
      url: HOST + URL,
      method: 'post',
      headers: this.makeHeader("POST"),
      data: JSON.stringify(bodyObject),
    });
    return {
      status: res.status,
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

}

export default new RequestHelper();